<template>
  <div class="page-container">
    <h3>
      {{ $t("browser_close.txt1") }}
    </h3>
    <img src="../../assets/img/ALLOSURVIE_App_EcranAccueilMobile.svg" alt="" />
  </div>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>
.page-container {
  height: 100%;
  h3 {
    margin-top: calc(min(100px, 10%));
    padding: 0px 30px;
  }

  img {
    position: absolute;
    bottom: -100px;
    left: 50%;
    transform: translate(-50%, 0);
  }
}
</style>
