/**
 * Store all relations between ids and images
 * path.
 *
 * Relations can be found in this document :
 * https://drive.google.com/drive/u/0/folders/1-S2S7qWd3qlH1jqAjzYrAdwset4NnNVh
 */

export default {
    CORDELETTE: '/img/illustrations/Item_01.png',
    FIL_DE_FER: '/img/illustrations/Item_02.png',
    BATON: '/img/illustrations/Item_03.png',
    ORTIES: '/img/illustrations/Item_04.png',
    PLANTAIN: '/img/illustrations/Item_05.png',
    PISSENLIT: '/img/illustrations/Item_06.png',
    FOUGERES: '/img/illustrations/Item_07.png',
    FOURMILIERE: '/img/illustrations/Item_08.png',
    SAUTERELLES: '/img/illustrations/Item_09.png',
    GRILLONS: '/img/illustrations/Item_10.png',
    ESCARGOTS: '/img/illustrations/Item_11.png',
    CHAMPIGNONS_1: '/img/illustrations/Item_12.png',
    CHAMPIGNONS_2: '/img/illustrations/Item_13.png',
    POISSON: '/img/illustrations/Item_14.png',
    TRACES_DE_LIEVRE: '/img/illustrations/Item_15.png',
    TRACES_DE_RENARD: '/img/illustrations/Item_16.png',
    MARRONS: '/img/illustrations/Item_17.png',
    CHATAIGNES: '/img/illustrations/Item_18.png',
    PLANTE_TOXIQUE: '/img/illustrations/Item_19.png',
    VERS_DE_TERRE: '/img/illustrations/Item_20.png',
    CHARBON: '/img/illustrations/Item_21.png',
    ZONE_FEU_1: '/img/illustrations/Item_21.png',
    ZONE_FEU_2: '/img/illustrations/Item_22.png',
    TRACES_DE_GIBIER: '/img/illustrations/Item_23.png',
    BRANCHAGES: '/img/illustrations/Item_24.png',
    TRONC: '/img/illustrations/Item_25.png',
    TRONC_HEXAGONE: '/img/illustrations/Item_25_hexa.png',
    PETIT_BOIS_1: '/img/illustrations/Item_26.png',
    PETIT_BOIS_2: '/img/illustrations/Item_27.png',
    PETIT_BOIS_3: '/img/illustrations/Item_28.png',
    ECORCES: '/img/illustrations/Item_29.png',
    AMADOU: '/img/illustrations/Item_30.png',
    PAILLE: '/img/illustrations/Item_31.png',
    SILEX: '/img/illustrations/Item_32.png',
    BRANCHE_COURBE: '/img/illustrations/Item_33.png',
    PLANCHETTE: '/img/illustrations/Item_34.png',
    BOUTEILLE: '/img/illustrations/Item_35.png',
    SABLE: '/img/illustrations/Item_36.png',
    GRAVIER: '/img/illustrations/Item_37.png',
    FLAQUE: '/img/illustrations/Item_39.png',
    EAU_DE_LA_MARE: '/img/illustrations/Item_40.png',
    MARE_HEXAGONE: '/img/illustrations/Item_40_hexa.png',
    EAU_VIVE: '/img/illustrations/Item_41.png',
    GROTTE_1: '/img/illustrations/Item_42.png',
    GROTTE_1_HEXAGONE: '/img/illustrations/Item_42_hexa.png',
    GROTTE_2: '/img/illustrations/Item_43.png',
    GROTTE_2_HEXAGONE: '/img/illustrations/Item_43_hexa.png',
    CABANE: '/img/illustrations/Item_44.png',
    CABANE_HEXAGONE: '/img/illustrations/Item_44_hexa.png',
    COUTEAU: '/img/illustrations/Item_45.jpg',
    GOURDE: '/img/illustrations/Item_46.jpg',
    FIRESTEEL: '/img/illustrations/Item_47.jpg',
    KIT_DE_PECHE: '/img/illustrations/Item_48.jpg',
    PASTILLES_DE_CHLORE: '/img/illustrations/Item_49.jpg',
    COUVERTURE: '/img/illustrations/Item_50.jpg',
    HARPON: '/img/illustrations/Item_51.png',
    COLLET_CORDE: '/img/illustrations/Item_52.png',
    FILTRE_A_EAU: '/img/illustrations/Item_53.jpg',
    CANNE: '/img/illustrations/Item_54.png',
    ARCHET: '/img/illustrations/Item_55.png',
    ABRI: '/img/illustrations/Item_56.png',
    COLLET_FER: '/img/illustrations/Item_61.png',
};

/**
 * Just the names of files
 */
const moralFaces = {
    level01: '/img/moralicons/JaugeComp_Etat05.svg.png',
    level02: '/img/moralicons/JaugeComp_Etat04.svg.png',
    level03: '/img/moralicons/JaugeComp_Etat03.svg.png',
    level04: '/img/moralicons/JaugeComp_Etat02.svg.png',
    level05: '/img/moralicons/JaugeComp_Etat01.svg.png',
};

export { moralFaces };
