<template>
    <div class="home-container">
        <img class="logo-badge" src="../../assets/img/ALLOSURVIE_Badge_Logo_FR.png" alt="badge logo" />
        <h1>{{ $t('browser_home_ios.title') }}</h1>
        <!-- <h3>
            {{ $t('browser_home_ios.subtitle') }}
        </h3> -->

        <div class="instruction-line">
            <div class="circle-container"><span class="circle">1</span></div>
            <p>
                {{ $t('browser_home_ios.txt1_part1') }}
                <img class="picto-app-ios" src="../../assets/img/ALLOSURVIE_App_Picto_iOS.svg" alt="App Picto Ios" />
                {{ $t('browser_home_ios.txt1_part2') }}
            </p>
        </div>

        <div class="instruction-line">
            <div class="circle-container"><span class="circle">2</span></div>
            <p>
                <span v-html="$t('browser_home_ios.txt2_part1')"></span>
                <img class="picto-app-ios" src="../../assets/img/ALLOSURVIE_App_Picto_AjoutEcran_iOS.svg" alt="App Picto Add Ios" />
                <span v-html="$t('browser_home_ios.txt2_part2')"></span>
            </p>
        </div>

        <div class="instruction-line">
            <div class="circle-container"></div>
            <p>
                {{ $t('browser_home_ios.txt3') }}
            </p>
        </div>

        <span class="browser-info"> ( {{ $t('browser_home_ios.browser_info') }}) </span>
    </div>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>
.home-container {
    width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .logo-badge {
        margin: 0 auto;
        max-width: 30%;
        max-height: 20%;
        object-fit: contain;
    }

    .browser-info {
        text-align: center;
        margin: 20px 0 0 0;
        font-size: 0.7rem;
        font-family: 'Rubik';
    }

    .instruction-line {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0.7rem 0;
        .circle-container {
            width: 5vh;
            height: 5vh;
            margin-right: 12px;
            span {
                display: block;
                width: 100%;
                height: 100%;
                font-size: 1.3rem;
                line-height: 5vh;
                color: #ffffec;
                background-color: #004744;
                text-align: center;
                border-radius: 50%;
            }
        }
        p {
            flex-basis: 100%;
            .picto-app-ios {
                height: 3.1vh;
                vertical-align: middle;
            }
        }
    }
}
</style>
