import Vue from 'vue';
import i18n from './i18n';
import router from './router';
import routerBrowserApp from './router/browserApp';
import store from './store';

import App from './App.vue';
import AppBrowser from '@/AppBrowser';

Vue.config.productionTip = false;

if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register('service-worker.js').then(function() {
        console.log('Service worker registered');
    });
}

if (window.matchMedia('(display-mode: standalone)').matches) {
    new Vue({
        router: router,
        store,
        i18n,
        render: (h) => h(App),
    }).$mount('#app');
} else {
    new Vue({
        router: routerBrowserApp,
        store,
        i18n,
        render: (h) => h(AppBrowser),
    }).$mount('#app');
}
