<template>
    <div class="dialog-home">
        <MlCamera
            v-if="isInScanMode"
            :model="config.globalAppModelURL"
            :accepted-labels-list="scanList"
            :use-navigation="false"
            :can-go-to-theo="true"
            @collect="onCollect"
        />
        <!-- <div class="temp-btn">
            <button @click="saveState">Save</button>
            <button @click="loadState">Load</button>
        </div> -->

        <div class="navbar">
            <div class="navbar-left">
                <img src="@/assets/img/ALLOSURVIE_Logo_texte_FR.svg" role="presentation" />
                <div class="moral-indicator">
                    <img class="face" :src="currentMoralFace" :alt="currentMoralFaceDescription"/>
                </div>
            </div>

            <div class="navbar-right">
                <Inventory ref="inventory" @open-crafting="openCraftingBook" @on-open="openInventory" @on-close="onInventoryClosed" />
                <CraftingBook ref="craftBook" @open-inventory="openInventory" @object-crafted="onObjectCrafted" @on-close="onInventoryClosed" />
                <img id="settings" src="@/assets/img/icone_reglages.svg" @click="openSettings" role="button" aria-label="Ouvrir les réglages" />
            </div>
        </div>

        <Settings v-if="isSettingsPanelOpen" @on-close="onSettingsClosed" @language-changed="reloadScript" />

        <div class="messages" :style="ariaContentShouldBeHiddenFlex" role="log">
            <MessageBubble v-for="(msg, i) in messages" :key="i" :message="msg" />
        </div>

        <transition name="actionstransition">
            <ActionsDialog
                v-if="actionsDialogVisible"
                :type="actionType"
                :choices="possibleChoices"
                @on-action="onAction"
                @on-action-input="onActionInput"
                :style="ariaContentShouldBeHidden"
                role="log"
                aria-live="polite"
                aria-label="Menu d'actions"
            />
        </transition>

        <!-- MALAISE MODAL -->
        <transition name="malaise-trans">
            <Malaise v-if="malaiseIsOccuring" @malaise-end="malaiseEnd" />
        </transition>

        <!-- NIGHT MODAL -->
        <transition name="night-trans">
            <Night v-if="itsNightTime" :text="nightPanelText" @animation-end="OnNightAnimationEnd" />
        </transition>
    </div>
</template>

<script>
import StoryManager from '../story/StoryManager';
import InventoryManager from '@/story/InventoryManager';
import { moralFaces } from '@/story/ImageDatabase';

import MessageBubble from '../components/MessageBubble';
import ActionsDialog from '../components/ActionsDialog';

import Settings from './Settings';
import Inventory from '@/views/Inventory';
import CraftingBook from '@/views/CraftingBook';
import Malaise from './Malaise';
import Night from './Night';
import ItemsDatabase from '@/story/ItemsDatabase';

import MlCamera from '@/views/MlCamera';

export default {
    components: {
        MessageBubble,
        ActionsDialog,
        Settings,
        Malaise,
        Night,
        Inventory,
        CraftingBook,

        MlCamera,
    },

    data() {
        return {
            messages: [],
            msgScrollViewElem: null,
            msgTween: null,
            possibleChoices: [],
            hideNextChoice: false,
            actionsDialogVisible: false,
            tags: require('../story/StoryTags').default,
            config: require('../utils/config').default,
            inventoryManager: require('@/story/InventoryManager').default,

            actionType: 0,

            isSettingsPanelOpen: false,
            isInvenotryOpen: false,

            moralProgressElement: null,
            currentMoralFace: '',
            currentMoralFaceDescription: 'Moral bas',
            malaiseIsOccuring: false,

            itsNightTime: false,
            nightPanelText: '',

            isInScanMode: false,
            scanList: [],

            savedStory: null,
        };
    },

    mounted() {
        this.init();
    },

    computed: {
        textSpeed() {
            return (5.0 - this.$store.state.textSpeed) * 1000;
        },
        textLang() {
            return this.$store.state.textLang;
        },
        ariaContentShouldBeHiddenFlex() {
            return this.isSettingsPanelOpen || this.isInScanMode || this.isInvenotryOpen ? 'display: none;' : 'display: flex;';
        },

        ariaContentShouldBeHidden() {
            return this.isSettingsPanelOpen || this.isInScanMode || this.isInvenotryOpen ? 'display: none;' : 'display: block;';
        },
    },

    methods: {
        saveState() {
            StoryManager.saveStoryState();
        },
        loadState() {
            StoryManager.loadStoryState();
        },
        reloadScript() {
            console.log('Reload script using this language : ', this.textLang);
            this.init();

            // this.$router.go();
            // StoryManager.loadAScript(this.textLang);
        },
        init() {
            this.messages = [];
            this.$store.commit('setInventoryContent', { value: [] });

            console.log(this.textLang);
            StoryManager.loadAScript(this.textLang);

            this.moralProgressElement = document.getElementById('moral-progress');
            this.currentMoralFace = this.config.publicAssetsBaseUrl + moralFaces.level04;
            this.msgScrollViewElem = this.$el.getElementsByClassName('messages')[0];

            // Setup night text
            this.nightPanelText = this.$t('night.firstnightlbl');

            setTimeout(() => {
                StoryManager.addVariableObserver('playerMorale', this.moralCallback);
                InventoryManager.setup();

                // Keep track of inventory content
                StoryManager.addVariableObserver('In_Inventory', (varName, newVal) => {
                    this.$store.commit('setInventoryContent', { value: [] });
                    let tmp = [];
                    for (var i of newVal.keys()) {
                        tmp.push(JSON.parse(i).itemName);
                    }

                    this.$store.commit('setInventoryContent', { value: tmp });
                });

                this.continueStory();
            }, 1500);
        },
        /**
         * Call on player moral change
         */
        moralCallback(varName, newValue) {
            if ((20 >= newValue && newValue > 16) || newValue > 20) {
                this.currentMoralFace = this.config.publicAssetsBaseUrl + moralFaces.level01;
                this.currentMoralFaceDescription = "Moral très bas";
            } else if (16 >= newValue && newValue > 12) {
                this.currentMoralFace = this.config.publicAssetsBaseUrl + moralFaces.level02;
                this.currentMoralFaceDescription = "Moral bas";
            } else if (12 >= newValue && newValue > 8) {
                this.currentMoralFace = this.config.publicAssetsBaseUrl + moralFaces.level03;
                this.currentMoralFaceDescription = "Moral normal";
            } else if (8 >= newValue && newValue > 4) {
                this.currentMoralFace = this.config.publicAssetsBaseUrl + moralFaces.level04;
                this.currentMoralFaceDescription = "Moral bon";
            } else if (4 >= newValue && newValue > 0) {
                this.currentMoralFace = this.config.publicAssetsBaseUrl + moralFaces.level05;
                this.currentMoralFaceDescription = "Moral très bon";
            } else {
                this.currentMoralFace = this.config.publicAssetsBaseUrl + moralFaces.level05;
                this.currentMoralFaceDescription = "Moral très bon";
            }
        },

        /**
         * Called when malaise finished
         */
        malaiseEnd() {
            this.malaiseIsOccuring = false;
            setTimeout(() => {
                this.continueStory();
            }, 2000);
        },

        /**
         * Process commands from message
         */
        processCommand(msg) {
            msg.shouldBePushed = true;
            msg.toTrigger = null;

            for (let i in msg.commands) {
                this.actionType = 0;
                msg.hasImage = false;

                // COMMAND THEO_IMAGE
                if (msg.commands[i] === this.tags.theo_img || msg.commands[i] === this.tags.player_img) {
                    msg.imgPath = ItemsDatabase[msg.sentence.trim()].image;
                    msg.sentence = '';
                    msg.hasImage = true;
                } else if (msg.commands[i] === this.tags.prompt) {
                    // COMMAND PROMPT_PLAYERNAME
                    this.actionType = 1;
                } else if (msg.commands[i] === this.tags.inventory) {
                    // msg.imgPath = ItemsDatabase[msg.sentence.trim()].image;
                    // msg.hasImage = true;
                    msg.sentence = this.$t('items.' + msg.sentence.trim()) + ' → ' + this.$t('home.dans_mon_sac');

                    // Set inventory notified
                    this.$store.commit('setIsInventoryNotified', { value: true });
                } else if (msg.commands[i] === this.tags.craft_blank) {
                    this.inventoryManager.craftRecipes.forEach((val, index) => {
                        if (val.name === msg.sentence.trim()) {
                            this.$store.commit('setCurrentRecipeIndex', { value: index });
                            val.enabled = true;

                            // Set inventory notified
                            this.$store.commit('setIsInventoryNotified', { value: true });
                        }
                    });

                    msg.shouldBePushed = false;
                    msg.toTrigger = this.tags.craft_blank;
                } else if (msg.commands[i] === this.tags.craft_blueprint) {
                    this.inventoryManager.craftRecipes.forEach((val, index) => {
                        if (val.name === msg.sentence.trim()) {
                            this.$store.commit('setCurrentRecipeIndex', { value: index });
                            val.enabled = true;
                            val.recipe_available = true;

                            // Set inventory notified
                            this.$store.commit('setIsInventoryNotified', { value: true });
                        }
                    });

                    msg.shouldBePushed = false;
                    msg.toTrigger = this.tags.craft_blank;
                } else if (msg.commands[i] === this.tags.craft_complete) {
                    // Block the conversation until craft is realized
                    msg.shouldBePushed = false;
                    this.$store.commit('setReadyToCraftIndex', { value: true });

                    // Set inventory notified
                    this.$store.commit('setIsInventoryNotified', { value: true });
                } else if (msg.commands[i] === this.tags.interface_craft) {
                    msg.shouldBePushed = false;
                    msg.toTrigger = this.tags.interface_craft;
                } else if (msg.commands[i] === this.tags.interface_inventory) {
                    msg.shouldBePushed = false;
                    msg.toTrigger = this.tags.interface_inventory;
                } else if (msg.commands[i] === this.tags.night) {
                    // Trigger the night screen and pause the story until return !
                    msg.shouldBePushed = false;
                    msg.toTrigger = this.tags.night;
                } else if (msg.commands[i] === this.tags.malaise) {
                    msg.shouldBePushed = false;
                    msg.toTrigger = this.tags.malaise;
                } else if (msg.commands[i] === this.tags.end) {
                    // Pop a button to go to the final screen
                    msg.shouldBePushed = false;
                    msg.toTrigger = this.tags.end;
                }
            }
        },

        addMessage(msg) {
            this.processCommand(msg);

            if (msg.shouldBePushed) {
                // Message is pushed to screen in a bubble
                this.messages.push(msg);

                setTimeout(() => {
                    this.msgScrollViewElem.scrollTop = this.msgScrollViewElem.scrollHeight;
                }, 50);

                return true;
            } // Message isn't pushed but an action is triggered
            else {
                switch (msg.toTrigger) {
                    case this.tags.craft_blank:
                        this.continueStory();
                        break;

                    case this.tags.interface_craft:
                        // Set inventory notified
                        this.$store.commit('setIsInventoryNotified', { value: true });
                        // Set craft screen should be consulted
                        this.$store.commit('setCraftScreenShouldBeConsulted', { value: true });
                        this.$store.commit('setCraftScreenConsulted', { value: false });
                        break;

                    case this.tags.interface_inventory:
                        console.log('INTERFACE INVENTORY TAG');
                        // Set inventory notified
                        this.$store.commit('setIsInventoryNotified', { value: true });
                        // Set craft screen should be consulted
                        this.$store.commit('setInventoryScreenShouldBeConsulted', { value: true });
                        this.$store.commit('setInventoryScreenConsulted', { value: false });
                        break;

                    case this.tags.night:
                        setTimeout(() => {
                            this.itsNightTime = true;
                        }, 2000);
                        break;

                    case this.tags.malaise:
                        setTimeout(() => {
                            this.malaiseIsOccuring = true;
                        }, 2000);
                        break;

                    case this.tags.end:
                        this.possibleChoices = [{ text: this.$t('home.Terminer') }];
                        this.actionsDialogVisible = true;
                        setTimeout(() => {
                            this.msgScrollViewElem.scrollTop = this.msgScrollViewElem.scrollHeight;
                        }, 300);
                        break;
                }

                return false;
            }
        },

        /**
         * Collect all story line until choice appear
         * Then collect all possible choices
         */
        continueStory() {
            /**
             * Get all available story lines before a choice
             * appear
             */
            let res = StoryManager.continueStory();
            if (res.continued) {
                let storyShouldContinue = this.addMessage(res);

                if (storyShouldContinue) {
                    setTimeout(() => {
                        this.continueStory();
                    }, this.textSpeed);
                }
            } else {
                /**
                 * Get all possible choices
                 */
                this.possibleChoices = StoryManager.getPossibleChoices();

                this.actionsDialogVisible = true;
                setTimeout(() => {
                    this.msgScrollViewElem.scrollTop = this.msgScrollViewElem.scrollHeight;
                }, 300);
            }
        },

        onAction(choice) {
            console.log(choice);
            if (choice.text === this.tags.scan) {
                // StoryManager.giveChoice(choice.index);
                StoryManager.giveChoice(choice);
                this.actionsDialogVisible = false;
                this.hideNextChoice = true;
                StoryManager.continueStory();
                this.possibleChoices = StoryManager.getPossibleChoices();

                this.scanList = [];
                for (let i = 0; i < this.possibleChoices.length; i++) {
                    this.scanList.push(this.possibleChoices[i].text);
                }

                // Open Scanner
                this.isInScanMode = true;
            } else if (choice.text === this.$t('home.Terminer')) {
                this.$router.push({ name: 'EndScreen' });
            } else {
                console.log(choice);

                StoryManager.giveChoice(choice);
                if (this.hideNextChoice) {
                    this.hideNextChoice = false;
                    StoryManager.continueStory();

                    this.addMessage({
                        commands: [this.tags.player_tag],
                        sentence: choice.text,
                    });
                }

                this.actionsDialogVisible = false;
                this.continueStory();
            }
        },

        onActionInput(input) {
            StoryManager.setVariable('playerName', input);
            StoryManager.giveChoice({ index: 0 });

            this.possibleChoices = [];
            this.actionsDialogVisible = false;

            // display the input in messages
            this.addMessage({
                commands: [this.tags.player_tag],
                sentence: input,
            });
            // Skip choice line
            StoryManager.continueStory();

            // continue the story
            this.continueStory();
        },

        /**
         * Settings
         */
        openSettings() {
            this.isSettingsPanelOpen = true;
        },

        onSettingsClosed() {
            this.isSettingsPanelOpen = false;
        },

        /**
         * Crafting Book
         */
        openCraftingBook() {
            this.isInvenotryOpen = true;
            this.$refs['craftBook'].isOpen = true;
        },

        onObjectCrafted() {
            this.$store.commit('setReadyToCraftIndex', { value: false });
            this.$store.commit('setCurrentRecipeIndex', { value: -1 });

            this.continueStory();
        },

        /**
         * Inventory
         */
        openInventory() {
            this.isInvenotryOpen = true;
            this.$refs['inventory'].isPanelOpened = true;
        },

        onInventoryClosed() {
            this.isInvenotryOpen = false;

            if (this.$store.state.craftScreenShouldBeConsulted && this.$store.state.craftScreenConsulted) {
                this.$store.commit('setCraftScreenShouldBeConsulted', { value: false });
                this.$store.commit('setCraftScreenConsulted', { value: false });
                this.continueStory();
            }

            if (this.$store.state.inventoryScreenShouldBeConsulted && this.$store.state.inventoryScreenConsulted) {
                this.$store.commit('setInventoryScreenShouldBeConsulted', { value: false });
                this.$store.commit('setInventoryScreenConsulted', { value: false });
                this.continueStory();
            }
        },

        /**
         * Night
         */
        OnNightAnimationEnd() {
            setTimeout(() => {
                this.itsNightTime = false;
                setTimeout(() => {
                    this.nightPanelText = this.$t('night.secondnightlbl');
                    this.continueStory();
                }, 2000);
            }, 1000);
        },

        /**
         * Scanner methods
         */
        onCollect(name) {
            console.log(name);
            let index = { index: 0, text: name };
            this.possibleChoices.forEach((val, i) => {
                // console.log(val.text, name, i);
                if (val.text === name) {
                    index.index = i;
                    console.log(name, i);
                }
            });

            this.isInScanMode = false;
            StoryManager.giveChoice(index);

            // Skip choice line display
            if (index.index !== 0) StoryManager.continueStory();
            this.continueStory();
        },
    },
};
</script>

<style lang="scss">
.temp-btn {
    position: absolute;
}

.dialog-home {
    display: grid;
    grid-template-rows: 60px 1fr;
    width: 100%;
    height: 100%;
    overflow: hidden;

    background-image: url('~@/assets/img/Texture_papier_dialogue_Tile.jpg');
    background-size: cover;

    .hidden-span
    {
        position: fixed;
        top: -100px;
    }

    .navbar {
        display: flex;
        width: 100%;
        height: 60px;

        background-color: rgb(0, 71, 68);

        img {
            width: auto;
            height: 60%;
        }

        .navbar-left {
            flex: 1;
            //width: 100%;
            height: 100%;
            display: flex;
            align-items: center;

            padding: 0 10px;

            .moral-indicator {
                position: relative;
                margin: 0 0 0 20px;

                img {
                    position: relative;
                    width: 100%;
                    max-width: 260px;
                    height: auto;
                    max-height: 55px;
                    z-index: 0;
                }
            }
        }

        .navbar-right {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 102px;
            height: 100%;

            background-color: rgb(254, 254, 235);

            #settings {
                margin: 0 15px 0 0;
            }
        }
    }

    .messages {
        display: flex;
        flex-direction: column;
        padding: 20px;

        overflow-y: auto;
        overflow-x: hidden;
        scroll-behavior: smooth;
    }
}
</style>
