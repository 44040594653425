<template>
    <div class="credits">
        <a class="credits-btn" @click="onCreditsBtnClicked" role="button">{{ $t('settings.creditsbtn') }}</a>

        <div class="credits-modal" v-if="isModalOpened">
            <div class="modal-container">
                <div class="modal-header">
                    <h1>{{ $t('settings.creditsbtn') }}</h1>
                    <img src="@/assets/img/croix_white.svg" @click="onCloseBtnClicked" role="button" aria-label="Fermer">
                </div>

                <div class="modal-content">
                    <h2>RENAISSANCES</h2>
                    <p>Une exposition Cité des sciences et de l'industrie</p><br>

                    <h3>ALLO SURVIE</h3><br>

                    <p><b>Président :</b> Bruno Maquart</p>
                    <p><b>Directrice générale déléguée :</b> Céline Prévost-Mouttalib</p>
                    <p><b>Directrice des expositions :</b> Michèle Antoine</p>

                    <h4>Muséographie</h4>
                    <p><b>Commissaire d’exposition :</b> Christelle Guiraud</p>
                    <p><b>Muséographes :</b> Géraldine Attié et Mark Read</p>

                    <h4>Multimédia</h4>
                    <p><b>Coordination multimédia :</b> Alain Rabussier</p>
                    <p><b>Conception production :</b> Bachibouzouk</p>
                    <ul>
                        <li><b>producteurs  :</b> Laurent Duret et Saskia Nilly</li>
                        <li><b>game designer  :</b> Florent Maurin</li>
                        <li><b>scénariste :</b> Alexandre Pierrin</li>
                        <li><b>chef de projet technique :</b> Jérémy Boistière</li>
                        <li><b>web développeurs :</b> Thomas Gredin et Yohann Le Breton</li>
                        <li><b>graphiste :</b> Brigitte Nataï</li>
                        <li><b>sound designer :</b> Antoine Wert</li>
                    </ul>
                    <p><b>Illustrations du décor incluses dans la webapp :</b> Tristan Maillet</p>
                    <p><b>Ingénierie multimédia :</b> Fodil Yazid, Philippe Herpin et Thomas Séchet</p>
                    <p><b>Intégration :</b> Vidéolio-IEC</p>

                    <h4>Traductions</h4>
                    <p>3I Traductions</p>

                    <br>

                    <p>
                        Cité des sciences et de l’industrie <br>
                        Un lieu Universcience © EPPDCSI – 2021
                    </p>

                    <div class="logo-container">
                        <img src="@/assets/img/logo_france.png" alt="Logo République Française">
                        <img src="@/assets/img/logo_cite.png" alt="Logo Cité des sciences et de l'industrie">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                isModalOpened: false
            }
        },

        methods: {
            onCreditsBtnClicked()
            {
                // Open Modal
                this.isModalOpened = true;
            },

            onCloseBtnClicked()
            {
                // Close Modal
                this.isModalOpened = false;
            }
        }
    }
</script>

<style lang="scss">
    .credits
    {
        .credits-btn
        {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 150px;
            height: 50px;

            background-color: #92ff97;
            color: rgb(0, 71, 68);
            border-radius: 3px;

            font-family: 'Londrina Solid', serif;
            font-size: 1.3em;
        }

        .credits-modal
        {
            position: fixed;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;

            padding: 25px;

            background-image: url("~@/assets/img/Texture_papier_blanc_casse_Tile750x1334.jpg");
            background-size: cover;

            overflow-y: auto;

            .modal-container
            {
                padding: 20px;
                background-color: #004744;
                color: #fefeeb;
                border-radius: 5px;
                box-shadow: 3px 3px 4px 0 rgba(56, 104, 93, 0.3);

                .modal-header
                {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    margin: 0 0 50px 0;

                    h1
                    {
                        margin: 0;

                        font-family: "Londrina Solid", cursive;
                        font-weight: 200;
                    }

                    img
                    {
                        width: auto;
                        height: 33px;
                    }
                }

                .modal-content
                {
                    display: flex;
                    flex-direction: column;
                    font-family: "Rubik regular", Avenir, Helvetica, Arial, sans-serif;

                    h2
                    {
                        margin: 0;
                    }

                    h3
                    {
                        text-align: left;
                    }

                    h4
                    {
                        font-size: 1.2em;
                        margin-bottom: 0;
                    }

                    p
                    {
                        margin: 0;
                        font-weight: 100;
                    }

                    b
                    {
                        font-family: 'Rubik Medium', Avenir, Helvetica, Arial, sans-serif;
                    }

                    ul
                    {
                        text-decoration: none;
                        li
                        {
                            margin-bottom: 0.5em;
                        }
                    }

                    .logo-container
                    {
                        display: flex;
                        margin-top: 25px;

                        img
                        {
                            margin-right: 25px;
                            width: 28%;
                            height: auto;
                        }
                    }
                }
            }
        }
    }
</style>