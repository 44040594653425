<template>
  <div class="item-container">
    <div class="item-slot" :class="itemDatabase[itemName].craft ? 'use-in-craft' : ''">
      <img class="item" :src="config.publicAssetsBaseUrl + itemDatabase[itemName].image" :alt="$t('items.' + itemName)" />
    </div>
    <span class="item-name">{{ $t('items.' + `${itemName}`) }}</span>
  </div>
</template>

<script>
export default {
  props: {
    itemName: String,
  },

  mounted() {
    console.log(this.$store.textLang);
  },

  data() {
    return {
      imageDatabase: require('@/story/ImageDatabase').default,
      itemDatabase: require('@/story/ItemsDatabase').default,
      config: require('@/utils/config').default,
    };
  },

  methods: {},
};
</script>

<style lang="scss" scoped>
.item-container {
  margin: 0 0 20px 0;
  width: 42vw;

  .item-slot {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 42vw;
    height: 42vw;

    background-color: #fefeeb;
    overflow: hidden;

    border: 3px solid #ccc; // #7ef884

    img.item {
      width: auto;
      max-width: 100%;
      height: auto;
      max-height: 100%;
    }

    &.use-in-craft {
      &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;

        border-width: 60px 0 0 60px;
        border-color: #7ef884 #7ef884 transparent transparent;
        border-style: solid;
      }

      &::before {
        content: '';
        background-image: url('~@/assets/img/useful_icon.png');
        background-size: cover;

        position: absolute;
        z-index: 1;
        top: 5px;
        right: 5px;

        width: 18px;
        height: 28px;
      }
    }
  }

  .item-name {
    display: inline-block;
    width: 100%;
    color: #fff;
    text-align: center;
    font-family: 'Londrina Solid', serif;
    font-size: 1.3rem;
    font-weight: 300;
  }
}
</style>
