import Vue from "vue";
import VueRouter from "vue-router";

import Home from "../../views/browserApp/Home";
import CloseBrowser from "../../views/browserApp/CloseBrowser";
import InstallationError from "../../views/browserApp/InstallationError";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/CloseBrowser",
    name: "CloseBrowser",
    component: CloseBrowser,
  },
  {
    path: "/installation-error",
    name: "InstallationError",
    component: InstallationError,
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

export default router;
