<template>
    <div class="popup-container">
        <div class="popup-header">
            <h2>{{ $t('craftingBook.manual_title') }}</h2>
            <img src="@/assets/img/croix_white.svg" @click="$emit('close-manual')" role="button" aria-label="Fermer" />
        </div>
        <div class="popup-body" v-html="$t('craftingBook.manual_text')"></div>
    </div>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>
.popup-container {
    background-color: #004744;
    color: #fefeeb;
    position: absolute;
    top: 5%;
    left: 5%;
    width: 90%;
    height: 90%;
    z-index: 99;
    border-radius: 2%;
    padding: 20px;
    .popup-header {
        display: flex;
        justify-content: space-between;
        h2 {
            max-width: 80%;
            margin: 0;
            font-size: 1.8rem;
            text-align: left;
            font-family: 'Londrina Solid', serif;
            font-weight: 500;
        }
        img {
            width: 30px;
        }
    }
    .popup-body {
        margin-top: 50px;
        /deep/ p {
            margin: 10px 0px;
        }
    }
}
</style>
