<template>
    <div class="message-bubble" :class="setClass(message.commands[0])" aria-live="polite">
        <span class="hidden-span" role="text">{{ senderName }},</span>
        <img v-if="message.hasImage" :src="config.publicAssetsBaseUrl + message.imgPath" role="presentation">
        <span v-html="message.sentence" ></span>
    </div>
</template>

<script>
    export default {
        props: {
            message: Object
        },

        data() {
            return {
                tags: require('../story/StoryTags').default,
                config: require('../utils/config').default,
                senderName: ""
            }
        },

        created()
        {
            this.senderName = this.getSenderNameLabel();
        },

        methods: {
            getSenderNameLabel()
            {
                switch(this.message.commands[0]) {
                    case this.tags.theo_tag:
                        return 'Theo';

                    case this.tags.player_tag:
                        return 'moi';

                    case this.tags.theo_img:
                        return 'Theo';

                    case this.tags.player_img:
                        return 'moi';

                    default:
                        return 'moi';
                }
            },

            setClass(tag)
            {
                switch(tag) {
                    case this.tags.theo_tag:
                        return 'left';

                    case this.tags.player_tag:
                        return 'right';

                    case this.tags.theo_img:
                        return 'left';

                    case this.tags.player_img:
                        return 'right';

                    default:
                        return 'right';
                }
            }
        }
    }
</script>

<style lang="scss">
    .message-bubble
    {
        margin: 0 0 10px 0;
        box-shadow: 3px 3px 4px 0 rgba(56, 104, 93, 0.3);

        .hidden-span
        {
            position: fixed;
            top: -100px;
        }

        &.left
        {
            position: relative;
            align-self: flex-start;
            max-width: 60%;
            padding: 5px;

            background-color: #f6ffee;
            color: #396054;
            border-radius: 0 15px 15px 5px;
            font-weight: 600;
            font-size: 1em;

            img
            {
                max-width: 100%;
                height: auto;
                background-color: #f6ffee;
                border-radius: 15px 5px 5px 15px;
            }

            span
            {
                display: inline-block;
                padding: 5px;
            }

            &::before
            {
                content:"\A";
                border-style: solid;
                border-width: 15px 0px 15px 15px;
                border-color: #f6ffee #f6ffee transparent transparent;
                position: absolute;
                top: 0;
                left: -15px;
            }
        }

        &.right
        {
            position: relative;
            align-self: flex-end;
            max-width: 60%;
            padding: 5px;

            background-color: #92ff97;
            color: #396054;
            border-radius: 15px 0 5px 15px;
            font-weight: 600;
            font-size: 1em;

            img
            {
                max-width: 100%;
                height: auto;
                background-color: #f6ffee;
                border-radius: 15px 5px 5px 15px;
            }

            span
            {
                display: inline-block;
                padding: 5px;
            }

            &::before
            {
                content:"\A";
                border-style: solid;
                border-width: 0px 15px 15px 15px;
                border-color: transparent  transparent transparent #92ff97;
                position: absolute;
                top: 0;
                left: 100%;
            }
        }
    }
</style>