<template>
    <div class="malaise">
        <div class="content">
            <h1 id="malaise-title" aria-live="polite">
                {{ $t('malaise.text01') }}
                <br />
                {{ $t('malaise.text02') }}
            </h1>

            <div class="moral-indicator">
                <div max="100" value="75" class="moral-progress">
                    <span id="moral-progress" class="level-05"></span>
                </div>
                <img src="@/assets/img/Jauge_Barre.svg" />
                <img class="face" :src="config.publicAssetsBaseUrl + '/img/moralicons/Jauge_Etat01.svg'" />
            </div>

            <p aria-live="polite">{{ $t('malaise.text03') }} {{ timeToWait }} {{ $t('malaise.text04') }}</p>
            <p aria-live="polite">{{ $t('malaise.text05') }}</p>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            timeToWait: 10,
            config: require('../utils/config').default,
        };
    },

    mounted() {
        setInterval(() => {
            this.timeToWait -= 1;
        }, 1000);

        setTimeout(() => {
            this.$emit('malaise-end');
        }, 10000);
    },
};
</script>

<style lang="scss">
.malaise {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        background-image: url('~@/assets/img/Malaise01.jpg');
        background-size: cover;

        filter: opacity(1) blur(0px);

        animation-name: gifback;
        animation-duration: 0.5s;
        animation-iteration-count: infinite;
    }

    @keyframes gifback {
        0% {
            background-image: url('~@/assets/img/Malaise01.jpg');
        }
        50% {
            background-image: url('~@/assets/img/Malaise01.jpg');
        }
        51% {
            background-image: url('~@/assets/img/Malaise02.jpg');
        }
        100% {
            background-image: url('~@/assets/img/Malaise02.jpg');
        }
    }

    .content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        position: relative;
        width: 100%;
        height: 100%;

        #malaise-title {
            color: rgb(254, 254, 235);
            font-family: 'Londrina Solid';
            font-weight: 200;

            text-align: center;
            line-height: 0.9em;
        }

        .moral-indicator {
            position: relative;
            margin: 30px 0 30px 0;

            img {
                position: relative;
                width: auto;
                height: 60px;
                z-index: 0;
            }

            .face {
                position: absolute;
                top: 0;
                left: 1px;
            }

            .moral-progress {
                position: absolute;
                z-index: 0;
                top: 20%;
                left: 40px;

                width: 100%;
                height: 34px;

                background-color: transparent;

                border-radius: 3px;

                span {
                    display: inline-block;
                    height: 100%;

                    &.level-05 {
                        width: 83%;
                        border-radius: 0 5px 5px 0;
                        background-color: #004744;
                    }
                }
            }
        }

        p {
            margin: 0;
            font-weight: bold;
            text-align: center;
            font-size: 1.2em;

            color: rgb(254, 254, 235);
        }
    }
}

.malaise-trans-enter-active,
.malaise-trans-leave-active {
    transition: filter 1s;
}
.malaise-trans-enter,
.malaise-trans-leave-to {
    filter: opacity(0);
}
</style>
