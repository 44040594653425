<template>
    <div class="actions" aria-live="polite">
        <div v-if="type == 0" class="buttons">
            <span class="hidden-span" aria-live="assertive" role="text">Les réponses possibles sont</span>
            <ActionButton 
                v-for="(choice, i) in choices" :key="i" 
                :choice="choice" 
                @clicked="actionButtonClicked"
            />
        </div>

        <div v-if="type == 1" class="input-box">
            <input type="text" name="username" id="username-input" autofocus autocomplete="off">
            <img src="@/assets/img/send.svg" @click="onSendImageClicked" role="button" aria-label="Confirmer">
        </div>
    </div>
</template>

<script>
    import ActionButton from './ActionsDialog/ActionButton'

    export default {
        data() {
            return {
                nameInput: null
            }
        },

        components: {
            ActionButton
        },

        props: {
            type: Number,
            choices: Array
        },

        mounted() {
            this.nameInput = this.$el.querySelector("#username-input");
        },

        methods: {
            actionButtonClicked(choice) {
                this.$emit('on-action', choice);
            },

            onSendImageClicked()
            {
                this.$emit('on-action-input', this.nameInput.value);
            }
        }
    }
</script>

<style lang="scss">
    .actions
    {
        padding: 20px 20px 20px 20px;
        height: auto;
        max-height: 800px;

        overflow-x: hidden;
        overflow-y: auto;

        background-image: url("~@/assets/img/Texture_Fond_choix_Tile750x750.jpg");
        background-size: cover;

        .hidden-span
        {
            position: fixed;
            top: -100px;
        }

        .input-box
        {
            display: flex;
            justify-content: center;
            align-items: center;

            input
            {
                width: 100%;
                height: 33px;

                background-color: rgb(254, 254, 235);
                color: #396054;
                border: 2px solid #7ef884;
                border-radius: 33px;
                text-align: center;

                font-family: 'Londrina Solid', serif;
                font-size: 1.2em;

                outline: none;
            }

            img
            {
                display: block;
                width: auto;
                height: 33px;
                margin: 0 0 0 20px;
            }
        }
    }

    /** TRANSITIONS */

    .actionstransition-enter-active, .actionstransition-leave-active 
    {
        transition: max-height 0.3s linear;
    }

    .actionstransition-enter, .actionstransition-leave-to 
    {
        max-height: 0;
    }
</style>