<template>
  <HomeLayoutIOS v-if="this.$store.state.deviceIsiOS" />
  <HomeLayoutAndroid v-else />
</template>
<script>
import HomeLayoutAndroid from "@/components/Browser/HomeLayoutAndroid";
import HomeLayoutIOS from "@/components/Browser/HomeLayoutIOS";

export default {
  components: {
    HomeLayoutAndroid,
    HomeLayoutIOS,
  },
};
</script>
<style lang="scss" scoped></style>
