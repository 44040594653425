exports.default = {
    theo_tag: "[THEO_DIAL]",
    player_tag: "[PLAYER_DIAL]",
    theo_img: "[THEO_IMG]",
    player_img: "[PLAYER_IMG]",
    prompt: "[PROMPT_PLAYERNAME]",
    malaise: "[INCIDENT_FAINT]",
    night: "[INCIDENT_NIGHT]",
    inventory: "[PLAYER_INVENTORY]",
    interface_craft: "[INTERFACE_CRAFT]",
    interface_inventory: "[INTERFACE_INVENTORY]",

    craft_blank: "[CRAFT_BLANK]",
    craft_blueprint: "[CRAFT_BLUEPRINT]",
    craft_complete: "[CRAFT_COMPLETE]",

    // Tag that indicate the end of the story
    end: "[END]",

    // Tag from possible choices
    scan: "SCANNER"
}