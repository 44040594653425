<template>
    <div class="not-compatible">
        <div class="body-container">
            <div class="button" @click="onClickRetry">
                <p v-html="$t('notCompatible.retry')"></p>
            </div>
            <!-- <img src="../assets/img/smiley_sad.png" alt="smiley sad" /> -->
            <p>{{ $t('notCompatible.text01') }}</p>
            <p>{{ $t('notCompatible.text02') }}</p>
            <div class="button" @click="onClickNotebook">
                <p v-html="$t('notCompatible.btn')"></p>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    methods: {
        onClickRetry() {
            this.$router.push({ name: 'EnterAppScan' });
        },
        onClickNotebook() {
            window.open("https://allosurvie.fr/livretdigital/index.php","_self")
        },
    },
};
</script>
<style lang="scss" scoped>
@import '../assets/fonts/fonts.scss';

.not-compatible {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2000;
    width: 100%;
    height: 100%;

    background-image: url('../assets/img/Texture_papier_blanc_casse_Tile750x1334.jpg');
    background-size: 300px;
    .body-container {
        padding: 80px 40px 40px 40px;
        text-align: center;
        img {
            width: 60px;
            height: 60px;
        }
        p {
            font-family: 'Rubik';
            color: #004744;
            font-size: 1.125rem;
            font-weight: 500;
            margin: 40px 0px;
        }

        .button {
            background-color: #7ef884;
            margin: 0 auto;
            width: 90%;
            max-height: 100px;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            cursor: pointer;
            padding: 1em;

            p {
                font-family: 'Londrina solid';
                // padding: 2em 2em;
                margin: 0;
                color: #004744;
                font-weight: 500;
                font-size: 1.8rem;
                text-transform: uppercase;
            }
        }
    }
}
</style>
