<template>
    <div id="app" :class="appClass">
        <router-view />
    </div>
</template>

<script>
const fontSizeClasses = {
    '0.5': 'small-txt',
    '1': 'normal-txt',
    '1.5': 'medium-txt',
    '2': 'big-txt',
};

export default {
    data() {
        return {
            appClass: '',
        };
    },

    created() {
        let lang = navigator.language || navigator.userLanguage;
        console.log(navigator.language, navigator.language, lang);

        if (lang.includes('fr')) {
            this.$store.commit('setTextLang', { value: 'fr', i18n: this.$i18n });
        } else if (lang.includes('es')) {
            this.$store.commit('setTextLang', { value: 'es', i18n: this.$i18n });
        } else {
            this.$store.commit('setTextLang', { value: 'en', i18n: this.$i18n });
        }
        console.log(this.textLang);
    },
    mounted() {
        this.appClass = fontSizeClasses[String(this.textSize)];
    },

    computed: {
        textSize() {
            return this.$store.state.textSize;
        },
        textLang() {
            return this.$store.state.textLang;
        },
    },

    watch: {
        textSize(newVal, oldVal) {
            console.log('old', oldVal, 'new', newVal);
            this.appClass = fontSizeClasses[String(newVal)];
        },
    },
};
</script>

<style lang="scss">
@import 'assets/fonts/fonts.scss';

/**
 * Reset rules
 */
html,
body {
    width: 100%;
    height: 100%;
    // overflow: hidden;

    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

#app {
    width: 100%;
    height: 100%;

    font-family: 'Rubik regular', Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    &.small-txt {
        font-size: 9px;
    }

    &.normal-txt {
        font-size: 14px;
    }

    &.medium-txt {
        font-size: 16px;
    }

    &.big-txt {
        font-size: 20px;
    }
}

#nav {
    padding: 30px;

    a {
        font-weight: bold;
        color: #2c3e50;

        &.router-link-exact-active {
            color: #42b983;
        }
    }
}
</style>
