<template>
    <div class="end-screen">
        <h1>{{ $t('endScreen.title') }}</h1>
        <p>
            {{ $t('endScreen.text01') }}
            <br /><br />
            {{ $t('endScreen.text02') }}
        </p>
    </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.end-screen {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    box-sizing: border-box;
    padding: 50px 25px;

    background-image: url('~@/assets/img/Texture_papier_bleu_fonce_Tile750x1334.jpg');
    background-size: cover;
    color: white;

    justify-content: center;
    align-items: center;
    text-align: center;

    h1 {
        font-family: 'Londrina Solid', serif;
        font-weight: 400;
    }
}
</style>
