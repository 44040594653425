import Vue from 'vue';
import Vuex from 'vuex';

import { languages, textOptions } from '../utils/config';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        wrongLabel: '0',

        textSize: textOptions.size.medium,
        textSpeed: textOptions.speed.normal,
        textLang: languages.fr,

        inventoryContent: [],
        isInventoryNotified: false,

        currentRecipeIndex: -1,
        readyToCraft: false,

        craftScreenShouldBeConsulted: false,
        craftScreenConsulted: false,

        inventoryScreenShouldBeConsulted: false,
        inventoryScreenConsulted: false,

        deviceIsiOS: false,
    },

    mutations: {
        setDeviceIsIos(state, payload) {
            state.deviceIsiOS = payload.value;
        },
        setTextSize(state, payload) {
            state.textSize = payload.value;
        },

        setTextSpeed(state, payload) {
            state.textSpeed = payload.value;
        },

        setTextLang(state, payload) {
            state.textLang = payload.value;
            payload.i18n.locale = state.textLang;
            document.documentElement.lang = payload.value;
        },

        setInventoryContent(state, payload) {
            state.inventoryContent = payload.value;
        },

        setCurrentRecipeIndex(state, payload) {
            console.log('Craft available', payload.value);
            state.currentRecipeIndex = payload.value;
        },

        setReadyToCraftIndex(state, payload) {
            state.readyToCraft = payload.value;
        },

        setIsInventoryNotified(state, payload) {
            state.isInventoryNotified = payload.value;
        },

        setCraftScreenConsulted(state, payload) {
            state.craftScreenConsulted = payload.value;
        },

        setCraftScreenShouldBeConsulted(state, payload) {
            state.craftScreenShouldBeConsulted = payload.value;
        },

        setInventoryScreenConsulted(state, payload) {
            state.inventoryScreenConsulted = payload.value;
        },

        setInventoryScreenShouldBeConsulted(state, payload) {
            state.inventoryScreenShouldBeConsulted = payload.value;
        },
    },

    actions: {},

    modules: {},
});
