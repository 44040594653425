<template>
    <div class="home-container">
        <img class="logo-badge" src="../../assets/img/ALLOSURVIE_Badge_Logo_FR.png" alt="badge logo" />
        <h1>{{ $t('browser_home_android.title') }}</h1>
        <h3>
            {{ $t('browser_home_android.subtitle') }}
        </h3>

        <div :class="['button', this.promptReady ? '' : 'disabled']" @click="onClickBtn">
            <p v-if="this.promptReady" v-html="$t('browser_home_android.btn')"></p>
            <p v-else v-html="$t('browser_home_android.loading')"></p>
            <div class="progress-bar"></div>
        </div>
        <span class="browser-info"> ({{ $t('browser_home_android.browser_info') }}) </span>
    </div>
</template>
<script>
export default {
    data() {
        return {
            deferredPrompt: null,
            mounted: false,
            timeoutHandler: -1
        };
    },

    created() {
        this.mounted = false;

        window.addEventListener('beforeinstallprompt', (e) => {
            e.preventDefault();
            // Stash the event so it can be triggered later.
            this.deferredPrompt = e;
            // console.log("before instal prompt");

            clearTimeout(this.timeoutHandler);
        });

        window.addEventListener('appinstalled', () => {
            // console.log("app instaled");
            this.deferredPrompt = null;
            this.$router.push({ name: 'CloseBrowser' });
        });
    },
    mounted() {
        this.mounted = true;
        // this.deferredPrompt = null;
        // console.log(this.deferredPrompt);

        this.timeoutHandler = setTimeout(this.installTimeout, 45000);
    },

    computed: {
        promptReady() {
            if (!this.mounted) {
                return true;
            } else {
                return this.deferredPrompt !== null;
            }
        },
    },
    methods: {
        async install() {
            this.deferredPrompt.prompt();
        },

        onClickBtn: function() {
            if (this.deferredPrompt) {
                this.install();
            } else {
                console.log('Cant install PWA, check that you are using https');
            }
        },

        installTimeout()
        {
            this.$router.push({name: 'InstallationError'});
        }
    },
};
</script>
<style lang="scss" scoped>
.home-container {
    width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 15px;

    .logo-badge {
        margin: 0.2rem auto;
        max-width: 30%;
        max-height: 20%;
        object-fit: contain;
    }

    .browser-info {
        text-align: center;
        margin: 20px 0 0 0;
        font-size: 0.7rem;
        font-family: 'Rubik';
    }

    .button {
        position: relative;
        background-color: #7ef884;
        margin: 0 auto;
        width: 70%;
        // max-height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        cursor: pointer;
        padding: 1em 0em;

        .progress-bar {
            position: absolute;
            z-index: 1;
            left: 0;
            width: 0%;
            height: 100%;
            background-color: #7ef884;
        }

        &.disabled {
            background-color: #b1b1b1;

            @keyframes load {
                from {
                    width: 0%;
                }
                to {
                    width: 100%;
                }
            }
            .progress-bar {
                animation-name: load;
                animation-timing-function: linear;
                animation-duration: 45s;
                animation-fill-mode: forwards;
            }
            p {
                font-size: 1.2rem;
                text-transform: none;
            }
        }

        p {
            z-index: 10;
            font-family: 'Londrina solid';
            // padding: 2em 2em;
            margin: 0;
            color: #004744;
            font-weight: 500;
            font-size: 1.8rem;
            text-transform: uppercase;

            -webkit-user-select: none; /* Safari */
            -moz-user-select: none; /* Firefox */
            -ms-user-select: none; /* IE10+/Edge */
            user-select: none; /* Standard */
        }
    }
}
</style>
