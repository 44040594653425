/**
 * Store all relations between ids and items data
 */

export default {
    CORDELETTE: {
        craft: true,
        image: '/img/illustrations/Item_01.png',
    },
    FIL_DE_FER: {
        craft: true,
        image: '/img/illustrations/Item_02.png',
    },
    BATON: {
        craft: true,
        image: '/img/illustrations/Item_03.png',
    },
    ORTIES: {
        craft: false,
        image: '/img/illustrations/Item_04.png',
    },
    PLANTAIN: {
        craft: false,
        description: '',
        image: '/img/illustrations/Item_05.png',
    },
    PISSENLIT: {
        craft: false,
        description: '',
        image: '/img/illustrations/Item_06.png',
    },
    FOUGERES: {
        craft: true,
        description: '',
        image: '/img/illustrations/Item_07.png',
    },
    'FOUGERES-1': {
        craft: true,
        description: '',
        image: '/img/illustrations/Item_07b.png',
    },
    'FOUGERES-2': {
        craft: true,
        description: '',
        image: '/img/illustrations/Item_07c.png',
    },
    'FOUGERES-3': {
        craft: true,
        description: '',
        image: '/img/illustrations/Item_07d.png',
    },
    FOURMILIERE: {
        craft: false,
        description: '',
        image: '/img/illustrations/Item_08.png',
    },
    SAUTERELLES: {
        craft: false,
        description: '',
        image: '/img/illustrations/Item_09.png',
    },
    GRILLONS: {
        craft: false,
        description: '',
        image: '/img/illustrations/Item_10.png',
    },
    ESCARGOTS: {
        craft: false,
        description: '',
        image: '/img/illustrations/Item_11.png',
    },
    CHAMPIGNONS_1: {
        craft: false,
        description: '',
        image: '/img/illustrations/Item_12.png',
    },
    CHAMPIGNONS_2: {
        craft: false,
        description: '',
        image: '/img/illustrations/Item_13.png',
    },
    POISSON: {
        craft: false,
        description: '',
        image: '/img/illustrations/Item_14.png',
    },
    TRACES_DE_LIEVRE: {
        craft: false,
        description: '',
        image: '/img/illustrations/Item_15.png',
    },
    TRACES_DE_RENARD: {
        craft: false,
        description: '',
        image: '/img/illustrations/Item_16.png',
    },
    MARRONS: {
        craft: false,
        description: '',
        image: '/img/illustrations/Item_17.png',
    },
    CHATAIGNES: {
        craft: false,
        description: '',
        image: '/img/illustrations/Item_18.png',
    },
    PLANTE_TOXIQUE: {
        craft: false,
        description: '',
        image: '/img/illustrations/Item_19.png',
    },
    VERS_DE_TERRE: {
        craft: false,
        description: '',
        image: '/img/illustrations/Item_20.png',
    },
    CHARBON: {
        craft: false,
        description: '',
        image: '/img/illustrations/Item_21.png',
    },
    ZONE_FEU_1: {
        craft: false,
        description: '',
        image: '/img/illustrations/Item_21.png',
    },
    ZONE_FEU_2: {
        craft: false,
        description: '',
        image: '/img/illustrations/Item_22.png',
    },
    TRACES_DE_GIBIER: {
        craft: false,
        description: '',
        image: '/img/illustrations/Item_23.png',
    },
    BRANCHAGES: {
        craft: true,
        description: '',
        image: '/img/illustrations/Item_24.png',
    },
    'BRANCHAGES-1': {
        craft: true,
        description: '',
        image: '/img/illustrations/Item_24b.png',
    },
    'BRANCHAGES-2': {
        craft: true,
        description: '',
        image: '/img/illustrations/Item_24.png',
    },
    'BRANCHAGES-3': {
        craft: true,
        description: '',
        image: '/img/illustrations/Item_24b.png',
    },
    TRONC: {
        craft: true,
        description: '',
        image: '/img/illustrations/Item_25.png',
    },
    TRONC_HEXAGONE: {
        craft: true,
        description: '',
        image: '/img/illustrations/Item_25_hexa.png',
    },
    PETIT_BOIS_1: {
        craft: false,
        description: '',
        image: '/img/illustrations/Item_26.png',
    },
    PETIT_BOIS_2: {
        craft: false,
        description: '',
        image: '/img/illustrations/Item_27.png',
    },
    PETIT_BOIS_3: {
        craft: false,
        description: '',
        image: '/img/illustrations/Item_28.png',
    },
    ECORCES: {
        craft: false,
        description: '',
        image: '/img/illustrations/Item_29.png',
    },
    AMADOU: {
        craft: false,
        description: '',
        image: '/img/illustrations/Item_30.png',
    },
    PAILLE: {
        craft: false,
        description: '',
        image: '/img/illustrations/Item_31.png',
    },
    SILEX: {
        craft: false,
        description: '',
        image: '/img/illustrations/Item_32.png',
    },
    BRANCHE_COURBE: {
        craft: true,
        description: '',
        image: '/img/illustrations/Item_33.png',
    },
    PLANCHETTE: {
        craft: true,
        description: '',
        image: '/img/illustrations/Item_34.png',
    },
    BOUTEILLE: {
        craft: true,
        description: '',
        image: '/img/illustrations/Item_35.png',
    },
    SABLE: {
        craft: false,
        description: '',
        image: '/img/illustrations/Item_36.png',
    },
    GRAVIER: {
        craft: false,
        description: '',
        image: '/img/illustrations/Item_37.png',
    },
    FLAQUE: {
        craft: false,
        description: '',
        image: '/img/illustrations/Item_39.png',
    },
    EAU_DE_LA_MARE: {
        craft: false,
        description: '',
        image: '/img/illustrations/Item_40.png',
    },
    MARE_HEXAGONE: {
        craft: false,
        description: '',
        image: '/img/illustrations/Item_40_hexa.png',
    },
    EAU_VIVE: {
        craft: false,
        description: '',
        image: '/img/illustrations/Item_41.png',
    },
    GROTTE_1: {
        craft: false,
        description: '',
        image: '/img/illustrations/Item_42.png',
    },
    GROTTE_1_HEXAGONE: {
        craft: false,
        description: '',
        image: '/img/illustrations/Item_42_hexa.png',
    },
    GROTTE_2: {
        craft: false,
        description: '',
        image: '/img/illustrations/Item_43.png',
    },
    GROTTE_2_HEXAGONE: {
        craft: false,
        description: '',
        image: '/img/illustrations/Item_43_hexa.png',
    },
    CABANE: {
        craft: false,
        description: '',
        image: '/img/illustrations/Item_44.png',
    },
    CABANE_HEXAGONE: {
        craft: false,
        description: '',
        image: '/img/illustrations/Item_44_hexa.png',
    },
    COUTEAU: {
        craft: false,
        description: '',
        image: '/img/illustrations/Item_45.jpg',
    },
    GOURDE: {
        craft: false,
        description: '',
        image: '/img/illustrations/Item_46.jpg',
    },
    FIRESTEEL: {
        craft: false,
        description: '',
        image: '/img/illustrations/Item_47.jpg',
    },
    KIT_DE_PECHE: {
        craft: false,
        description: '',
        image: '/img/illustrations/Item_48.jpg',
    },
    PASTILLES_DE_CHLORE: {
        craft: false,
        description: '',
        image: '/img/illustrations/Item_49.jpg',
    },
    COUVERTURE: {
        craft: false,
        description: '',
        image: '/img/illustrations/Item_50.jpg',
    },
    HARPON: {
        craft: false,
        description: '',
        image: '/img/illustrations/Item_51.jpg',
    },
    COLLET_CORDE: {
        craft: false,
        description: '',
        image: '/img/illustrations/Item_52.jpg',
    },
    FILTRE_A_EAU: {
        craft: false,
        description: '',
        image: '/img/illustrations/Item_53.jpg',
    },
    CANNE: {
        craft: false,
        description: '',
        image: '/img/illustrations/Item_54.jpg',
    },
    ARCHET: {
        craft: false,
        description: '',
        image: '/img/illustrations/Item_55.jpg',
    },
    ABRI: {
        craft: false,
        description: '',
        image: '/img/illustrations/Item_56.jpg',
    },
    COLLET_FER: {
        craft: false,
        description: '',
        image: '/img/illustrations/Item_61.png',
    },
};
