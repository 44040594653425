export default {
    appVersionNumber: '2021.8.a',
    enterAppModelURL: process.env.VUE_APP_START_URL + 'model/entry/model.json',

    globalAppModelURL: process.env.VUE_APP_START_URL + 'model/global/model.json',

    scriptBaseURL: process.env.VUE_APP_START_URL + 'Script/',

    // publicAssetsBaseUrl: process.env.NODE_ENV !== 'production' ? 'https://bachibouzouk.net/dev/robinsonnade' : '',
    publicAssetsBaseUrl: process.env.VUE_APP_COMPLETE_URL,
};

const languages = {
    fr: 'fr',
    en: 'en',
    es: 'es',
};

const textOptions = {
    size: {
        small: 0.5,
        normal: 1,
        medium: 1.5,
        big: 2,
    },

    speed: {
        slow: 0,
        normal: 2,
        speed: 4,
    },
};

export { languages, textOptions };
