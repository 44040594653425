import Vue from 'vue';
import VueRouter from 'vue-router';

import Home from '../views/Home.vue';
import Connection from '@/views/Connection';

import MlCamera from '../views/MlCamera.vue';
import DialogHome from '../views/DialogHome.vue';
import NotCompatible from '@/views/NotCompatible';
import EndScreen from '@/views/EndScreen';

import Config from '../utils/config';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
    },
    {
        path: '/connection',
        name: 'Connection',
        component: Connection,
    },
    {
        path: '/dialoghome',
        name: 'DialogHome',
        component: DialogHome,
    },
    {
        path: '/enterappscan',
        name: 'EnterAppScan',
        component: MlCamera,
        props: {
            model: Config.enterAppModelURL,
            canGoToTheo: false,
            collectBtnText: 'mlcamera.beginbtn',
            acceptedLabelsList: ['ENTREE'],
            nextRoute: 'Connection',
            useNavigation: true,
        },
    },
    {
        path: '/notcompatible',
        name: 'NotCompatible',
        component: NotCompatible,
    },
    {
        path: '/end',
        name: 'EndScreen',
        component: EndScreen,
    },
];

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes,
});

export default router;
