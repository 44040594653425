/**
 * Story manager
 *
 * All commands can be found here:
 * https://docs.google.com/spreadsheets/d/1IJmKB2r4cA4s6ZZqHUygqogdAEXXJjGiKPDFAbpv8eo/edit#gid=0
 */

var Story = require('inkjs').Story;
import Config, { languages } from '../utils/config';

class StoryManager {
    story = null;
    save = null;

    constructor() {}

    /**
     * Select and load the correct json script
     * @param {String} language selected language
     */
    loadAScript(language) {
        const self = this;
        let scriptUrl = Config.scriptBaseURL;
        switch (language) {
            case languages.fr:
                scriptUrl += 'AlloSurvie-Script.ink.json';
                break;
            case languages.en:
                scriptUrl += 'AlloSurvie-Script_EN.ink.json';
                break;
            case languages.es:
                scriptUrl += 'AlloSurvie-Script_ES.ink.json';
                break;
            default:
                console.log('Specified language (', language, ') not valid => loading default script');
                scriptUrl += 'AlloSurvie-Script.ink.json';
                break;
        }

        console.log(scriptUrl);
        fetch(scriptUrl, { method: 'GET' })
            .then(function(response) {
                return response.text();
            })
            .then(function(storyContent) {
                self.setupStory(storyContent);
            });
    }
    /**
     * Build the story from JSON text content
     * @param {String} storyContent content of the JSON file
     */
    setupStory(storyContent) {
        this.story = new Story(storyContent);

        const saveGame = localStorage.getItem('storyState');
        if (saveGame) {
            console.log('Savegame found in local storage');
            this.story.state.LoadJson(saveGame);
        } else {
            console.log('Savegame not found');
        }
    }

    /**
     * Continue the story if possible and return the next sentence
     */
    continueStory() {
        if (this.story.canContinue) {
            let sentence = this.story.Continue();
            return this.processLine(sentence);
        }

        return { continued: false };
    }

    saveStoryState() {
        console.log('Saving state...');
        this.save = this.story.state.toJson();
        localStorage.setItem('storyState', this.save);
        console.log(this.save);
    }
    loadStoryState() {
        if (this.save) {
            console.log('Loading state...');
            // this.story = new Story(storyContent);
            this.story.state.LoadJson(this.save);
        }
    }

    /**
     * Get possible choices information currently available
     */
    getPossibleChoices() {
        return this.story.currentChoices;
    }

    /**
     * Give user selected choice to the story engine
     */
    giveChoice(choice) {
        this.story.ChooseChoiceIndex(choice.index);
    }

    /**
     * Set a variable in ink context.
     * @param {String} variable
     * @param {any} value
     */
    setVariable(variable, value) {
        this.story.variablesState.$(variable, value);
        return this.story.variablesState.$(variable);
    }

    /**
     * Get a variable that is stored in the ink state
     * @param {String} variable
     * @returns {any} value
     */
    getVariable(variable) {
        return this.story.variablesState.$(variable);
    }

    /**
     * Process a lign to extract all components :
     *  - Text
     *  - Commands
     * @param {String} line line to process
     */
    processLine(line) {
        // \[[a-z, A-Z, 0-9, _, -]*\] Regex to catch cusom commands
        const regex = /\[[a-z, A-Z, 0-9, _, -]*\]/g;
        let match = regex.exec(line);
        let commands = [];
        while (match !== null) {
            commands.push(match[0]);
            match = regex.exec(line);
        }

        // extract sentences
        line = line.replace(regex, '');

        // Construct and return object with commands
        return {
            continued: true,
            sentence: line,
            commands: commands,
        };
    }

    /**
     * Add an observer for the given variable in ink state
     * @param {String} varName
     * @param {Function} callback
     */
    addVariableObserver(varName, callback) {
        if (this.story.variablesState[varName]) {
            this.story.ObserveVariable(varName, callback);
        }
    }

    /**
     * Remove a given observer for the given variable in ink state
     * @param {String} varName
     * @param {Function} callback
     */
    removeVariableObserver(varName, callback) {
        this.story.RemoveVariableObserver(callback, varName);
    }
}
var storyManager = new StoryManager();
export default storyManager;
