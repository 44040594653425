<template>
    <div class="app">
        <span class="num-app-version" v-if="this.appVersion">v {{ this.appVersion }}</span>
        <router-view />
    </div>
</template>

<script>
import Config from '@/utils/config';

export default {
    data() {
        return {
            appVersion: '',
        };
    },
    mounted() {
        this.appVersion = Config.appVersionNumber;
    },
    beforeMount() {
        // Check if device is iPhone or Android
        if (navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
            this.$store.commit('setDeviceIsIos', { value: true });
        } else {
            this.$store.commit('setDeviceIsIos', { value: false });
        }

        // Set the correct language
        let lang = navigator.language || navigator.userLanguage;
        console.log(lang);
        if (lang.includes('fr')) {
            this.$store.commit('setTextLang', { value: 'fr', i18n: this.$i18n });
        } else if (lang.includes('es')) {
            this.$store.commit('setTextLang', { value: 'es', i18n: this.$i18n });
        } else {
            this.$store.commit('setTextLang', { value: 'en', i18n: this.$i18n });
        }
    },
};
</script>

<style>
@import './assets/fonts/fonts.scss';

* {
    box-sizing: border-box;
}
q {
    quotes: '\00AB''\00BB';
}

.app {
    font-family: 'Rubik', Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    margin: 0;
    min-height: 100%;
    width: 100%;
    position: absolute;
    background-image: url('./assets/img/Texture_papier_blanc_casse_Tile750x1334.jpg');
    background-size: 300px;

    color: #004744;
    overflow-y: hidden;
    display: flex;
    vertical-align: middle;
}
.app > * {
    max-width: 400px;
    margin: auto;
    padding: 20px 20px 50px 20px;
}
.num-app-version {
    position: fixed;
    bottom: 0px;
    right: 0px;
    font-size: 0.6em;
    margin: 0;
    padding: 0;
}

strong {
    font-weight: 600;
}

h1 {
    margin: 0.5rem 0;
    font-size: 1.7rem;
    font-weight: 300;
    font-family: 'Londrina solid';
    text-transform: uppercase;
    text-align: center;
}

h3 {
    margin: 0.5rem 0;
    font-size: 1.2rem;
    font-weight: 500;
    font-family: 'Rubik';
    text-align: center;
}

p {
    margin: 0;
    font-size: 0.95rem;
    font-family: 'Rubik';
}
</style>
