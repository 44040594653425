<template>
    <a class="choice-btn" @click="onClick()" aria-live="polite">
        <img v-if="isScanChoice(choice.text)" src="@/assets/img/icone_camera_inverse.svg" alt="BACK" />
        <span v-else v-html="choice.text" role="button" aria-live="polite"></span>
    </a>
</template>

<script>
export default {
    props: {
        choice: Object,
    },

    methods: {
        onClick() {
            this.$emit('clicked', this.choice);
        },

        isScanChoice(choiceText) {
            if (choiceText === 'SCANNER') {
                return true;
            }

            return false;
        },
    },
};
</script>

<style lang="scss">
.choice-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    margin: 0 0 10px 0;

    background-color: rgb(254, 254, 235);
    color: #396054;
    border-radius: 5px;

    font-family: 'Londrina Solid';
    font-size: 1.2em;

    img {
        width: auto;
        height: 75%;
    }

    span {
        text-align: center;
    }
}
</style>
