<template>
    <div class="crafting-book" v-if="isOpen">
        <CraftingBookManual v-if="showManual" @close-manual="toggleManualOverlay" />
        <div class="top-bar">
            <a class="back-btn" @click="closeCraftingBook" role="button" aria-label="Fermer">
                <img src="@/assets/img/back_arrow.png" />
            </a>

            <div class="titles">
                <div class="title" id="bag" @click="switchToInventory">
                    <img src="@/assets/img/icone_sac_inventaire.svg" />
                    <h1>{{ $t('inventory.sac_a_dos') }}</h1>
                </div>

                <div class="title" id="crafting">
                    <img src="@/assets/img/useful_icon.png" />
                    <h1>{{ $t('inventory.carnet_de_fabrication') }}</h1>
                </div>
            </div>
        </div>

        <div class="content">
            <h1>{{ $t('craftingBook.body_title') }}</h1>
            <h3>{{ $t('items.' + currentRecipe.name) }}</h3>

            <Blueprint :current-recipe="currentRecipe" @open-manual="toggleManualOverlay" />

            <div class="bouton" :class="readyToCraft ? 'active' : ''" @click="onCraftButtonClick" role="button">
                <span>{{ $t('craftingBook.craft_btn') }}</span>
            </div>

            <div class="object-slots">
                <div class="slot" v-for="(item, i) in currentRecipe.craft" :key="i">
                    <div class="slot-img-square">
                        <img :src="getImageUrl(item)" v-show="setVisibility(item)" alt="" :class="setClass(item)" />
                        <p v-show="!setVisibility(item)">?</p>
                    </div>
                    <span v-if="setVisibility(item)" class="item-name">{{ getTranslatedItemName(item) }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Blueprint from '@/components/CraftingBook/Blueprint';
import CraftingBookManual from '@/components/CraftingBook/CraftingBookManual';
import ItemsDatabase from '@/story/ItemsDatabase';
import storyManager from '@/story/StoryManager';
import config from '@/utils/config';

export default {
    components: {
        Blueprint,
        CraftingBookManual,
    },

    data() {
        return {
            isOpen: false,
            inventoryManager: require('@/story/InventoryManager').default,
            currentRecipe: null,

            branchages_names: ['BRANCHAGES-1', 'BRANCHAGES-2', 'BRANCHAGES-3'],
            fougeres_names: ['FOUGERES-1', 'FOUGERES-2', 'FOUGERES-3'],
            showManual: false,
        };
    },

    beforeUpdate() {
        this.currentRecipe = this.inventoryManager.craftRecipes[this.$store.state.currentRecipeIndex];
        console.log('Current recipe', this.currentRecipe);

        // Set craft screen as viewed if craft screen should be consulted
        if (this.$store.state.craftScreenShouldBeConsulted) {
            this.$store.commit('setCraftScreenConsulted', { value: true });
        }
    },

    computed: {
        readyToCraft() {
            return this.$store.state.readyToCraft;
        },
    },
    methods: {
        getTranslatedItemName(_item) {
            // const itemRef = ItemsDatabase[_item.name];
            // const itemName = itemRef.translationId !== null ? itemRef.translationId : _item.name;
            // console.log(itemRef, itemName);
            // return this.$t('items.' + itemName);
            return this.$t('items.' + _item.name);
        },
        closeCraftingBook() {
            this.$emit('on-close');
            this.isOpen = false;
        },
        toggleManualOverlay() {
            this.showManual = !this.showManual;
        },

        switchToInventory() {
            this.$emit('open-inventory');
            this.isOpen = false;
        },

        getImageUrl(item) {
            return config.publicAssetsBaseUrl + ItemsDatabase[item.name].image;
        },

        onCraftButtonClick() {
            if (!this.readyToCraft) return;

            this.closeCraftingBook();
            this.$emit('object-crafted');
        },

        setVisibility(item) {
            if (this.branchages_names.includes(item.name) && this.currentRecipe.recipe_available) {
                return true;
            } else if (this.fougeres_names.includes(item.name) && this.currentRecipe.recipe_available) {
                return true;
            } else if (this.$store.state.inventoryContent.includes(item.name) || this.currentRecipe.recipe_available) {
                return true;
            }

            return false;
        },

        setClass(item) {
            // Manage specifics cases
            if (this.branchages_names.includes(item.name)) {
                let index = Number.parseInt(item.name[item.name.length - 1]);
                console.log(index);

                if (index > storyManager.getVariable('BRANCHAGES')) return 'grayscaled';
            } else if (this.fougeres_names.includes(item.name)) {
                let index = Number.parseInt(item.name[item.name.length - 1]);

                if (index > storyManager.getVariable('FOUGERES')) return 'grayscaled';
            } else {
                if (!this.$store.state.inventoryContent.includes(item.name) && this.currentRecipe.recipe_available) return 'grayscaled';
            }

            return '';
        },
    },
};
</script>

<style lang="scss" scoped>
.crafting-book {
    position: absolute;
    z-index: 1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    background-color: #fefeeb;

    .top-bar {
        display: flex;
        width: 100%;
        height: 60px;

        background-color: #004744;

        .back-btn {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 60px;
            min-width: 60px;
            height: 100%;

            background-color: #92ff97;

            img {
                width: 30%;
                height: auto;
            }
        }

        .titles {
            display: flex;
            justify-content: space-around;

            width: 100%;

            .title {
                flex: 1;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                height: 100%;

                padding: 0 10px;

                img {
                    width: 20px;
                    height: auto;

                    margin: 0 10px 0 0;
                }

                h1 {
                    margin: 0;
                    line-height: 1em;

                    font-size: 1.2em;
                    font-family: 'Londrina Solid', serif;
                    font-weight: 400;
                    color: #fefeeb;
                }

                &#bag {
                    box-sizing: border-box;

                    border-width: 0 5px 5px 0;
                    border-color: transparent #d1e5ce #d1e5ce transparent;
                    border-style: solid;
                }

                &#crafting {
                    padding: 0 10px 0 15px;
                    background-color: #fefeeb;

                    h1 {
                        color: #004744;
                    }
                }
            }
        }
    }

    .content {
        box-sizing: border-box;
        height: calc(100% - 60px);
        padding: 20px;

        overflow-x: hidden;
        overflow-y: auto;

        h1 {
            margin: 0 0 10px 0;

            text-align: center;
            font-family: 'Londrina Solid', serif;
            font-weight: 400;
            color: #004744;
        }

        h3 {
            margin: 0 0 10px 0;

            text-align: center;
            font-family: 'Londrina Solid', serif;
            font-weight: 400;
            color: #004744;
        }

        .bouton {
            display: flex;
            justify-content: center;
            align-items: center;

            margin: 15px 0;

            width: 100%;
            height: 50px;
            background-color: lightgray;
            color: #677978;
            border: none;
            border-radius: 3px;

            font-family: 'Londrina Solid', cursive;
            font-weight: 0;
            font-size: 1.5em;

            &.active {
                background-color: rgb(126, 248, 132);
                color: rgb(0, 71, 68);
            }
        }

        .object-slots {
            display: grid;
            grid-template-columns: repeat(auto-fill, 100px);
            justify-content: space-between;
            grid-gap: 10px;
            .slot {
                margin: 0 0 10px 0;
                width: 28vw;

                .slot-img-square {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 28vw;
                    height: 28vw;
                    margin: 0 0 0px 0;

                    background-color: #fefeeb;
                    overflow: hidden;

                    border: 4px solid rgb(126, 248, 132);

                    img {
                        width: auto;
                        max-width: 100%;
                        height: auto;
                        max-height: 100%;

                        &.grayscaled {
                            filter: grayscale(1);
                        }
                    }

                    p {
                        font-family: 'Londrina Solid', serif;
                        font-size: 4em;
                        color: #7ef884;
                    }
                }
                .item-name {
                    display: inline-block;
                    width: 100%;
                    color: rgb(0, 71, 68);
                    text-align: center;
                    font-family: 'Londrina Solid', serif;
                    font-size: 1.2rem;
                    font-weight: 500;
                }
            }
        }
    }
}
</style>
