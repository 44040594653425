<template>
    <div id="cont" data-pct="100" :style="cssVars">
        <svg id="svg" width="120" height="120" viewPort="0 0 60 60" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <circle class="bar-border" r="50" cx="60" cy="60" fill="transparent" stroke-dasharray="314" stroke-dashoffset="0"></circle>
            <circle class="bar" r="50" cx="60" cy="60" fill="transparent" stroke-dasharray="314" stroke-dashoffset="0"></circle>
        </svg>
    </div>
</template>
<script>
export default {
    props: {
        duration: Number,
    },
    data() {
        return {
            percent: 0,
        };
    },

    created() {
        this.percent = 100;
    },
    mounted() {
        setTimeout(() => {
            this.percent = 0;
        }, 100);
    },

    computed: {
        cssVars() {
            const r = 50;
            const c = Math.PI * (r * 2);
            const pct = ((100 - this.percent) / 100) * c;

            return {
                '--pct': pct,
                '--anim-duration': this.duration.toString() + 's',
            };
        },
    },
};
</script>
<style lang="css" scoped>
#cont {
    position: absolute;
    top: 50vh;
    left: 50vw;
    transform: translate(-50%, -50%);
    height: 200px;
    width: 200px;
    /* box-shadow: 0 0 1em black; */
    border-radius: 100%;
    /* background-color: #fff; */
    display: flex;
    justify-content: center;
    align-items: center;
}

#svg {
}

#svg circle {
    /* stroke: #666; */
    stroke-dashoffset: var(--pct);
    transition: stroke-dashoffset var(--anim-duration) linear;
}
.bar {
    stroke-dashoffset: var(--pct);
    transition: stroke-dashoffset var(--anim-duration) linear;
    stroke: rgb(126, 248, 132);
    stroke-width: 0.5em;
}
.bar-border {
    stroke: #fff;
    stroke-width: 0.7em;
}
</style>
