<template>
    <div class="mlcamera">
        <CircleProgress v-if="this.$route.name === 'EnterAppScan'" :duration="this.countdownDuration" />
        <div v-if="isLoading" class="loading-screen">
            <span>{{ $t('mlcamera.chargement') }} ...</span>
            <img src="@/assets/img/icone_camera.svg" />
        </div>

        <div class="content">
            <div v-if="!isLoading" class="target">
                <img src="@/assets/img/cameratarget.svg" role="presentation" />
            </div>

            <div v-if="!isLoading" class="frame">
                <img src="@/assets/img/cameraframe.svg" role="presentation" />
            </div>

            <div v-if="isObjectDetected && isObjectUseless" class="useless-indicator">
                <span :aria-label="$t('mlcamera.uselessdetection')">{{ $t('mlcamera.uselessdetection') }}</span>
            </div>

            <div v-if="!isLoading && !isObjectDetected" class="instructions">
                <span role="alert" aria-live="assertive">{{ $t(getInstruction01()) }}</span>
                <br />
                <span role="alert" aria-live="assertive">{{ $t(getInstruction02()) }}</span>
            </div>

            <div v-if="!isLoading && isObjectDetected" class="object-label">
                <span :aria-label="detectedObjectName" role="alert" aria-live="assertive" v-show="detectedObjectName !== $t('items.ENTREE')">{{
                    detectedObjectName
                }}</span>
            </div>

            <video v-show="!isLoading" id="camera-video" muted autoplay playsinline></video>

            <div v-if="!isLoading" class="controls">
                <a v-if="canGoToTheo" id="help" @click="abandon" role="button"><img src="@/assets/img/back_arrow_inverted.png"/></a>
                <a
                    v-if="this.$route.name !== 'EnterAppScan'"
                    id="collect"
                    :class="isObjectDetected && !isObjectUseless ? '' : 'inactive'"
                    @click="processAction"
                    role="button"
                    ><img src="@/assets/img/icone_camera_inverse.svg"
                /></a>
            </div>
        </div>
    </div>
</template>

<script>
import CircleProgress from '../components/CircleProgress';

export default {
    components: { CircleProgress },
    props: {
        model: String,
        canGoToTheo: Boolean,
        collectBtnText: {
            type: String,
            default: 'RAMASSER',
        },
        acceptedLabelsList: Array,
        nextRoute: String,

        useNavigation: {
            type: Boolean,
            default: true,
        },
        actionToPerform: {
            type: Function,
            default: () => {
                console.log('Action should be used but it is not assigned...');
            },
        },
    },

    data() {
        return {
            ml5: require('ml5'),
            isLoading: true,
            isObjectDetected: false,
            isObjectUseless: true,
            detectedObjectName: '',
            detectedObjectTag: '',
            video: null,
            classifier: null,
            intervalClassify: null,
            countdownRef: null,
            countdownDuration: 25, // in seconds
        };
    },

    computed: {
        wrongLabel() {
            return this.$store.state.wrongLabel;
        },
    },

    mounted() {
        console.log(this.ml5.version);

        // Camera
        this.video = document.getElementById('camera-video');
        this.video.width = window.screen.width;
        this.video.height = window.screen.height;
        this.video.setAttribute('autoplay', '');
        this.video.setAttribute('muted', '');
        this.video.setAttribute('playsinline', true);

        navigator.mediaDevices
            .getUserMedia({ video: { facingMode: 'environment' } })
            .then((stream) => {
                this.HandleMediaDeviceSuccess(this, stream);
            })
            .catch((e) => {
                this.HandleMediaDeviceError(e);
            });

        // Image classifier model loading
        var prom = this.ml5.imageClassifier(this.model, this.video, 3);
        prom.then((classifier) => {
            this.modelReady(classifier);
        }).catch((err) => console.log('Error: ' + err));
    },

    unmounted() {},
    methods: {
        getInstruction01() {
            if (this.$route.name === 'EnterAppScan') {
                return 'mlcamera.entry_instructions01';
            } else {
                return 'mlcamera.instructions01';
            }
        },

        getInstruction02() {
            if (this.$route.name === 'EnterAppScan') {
                return 'mlcamera.entry_instructions02';
            } else {
                return 'mlcamera.instructions02';
            }
        },

        HandleMediaDeviceSuccess(self, _stream) {
            self.video.srcObject = _stream;
            self.video.onloadedmetadata = function() {
                self.video.play();
            };

            // If this is the first scan, launch a countdown to check if phone is compatible
            if (this.$route.name === 'EnterAppScan') {
                this.countdownRef = setTimeout(() => {
                    console.log('Not compatible');
                    this.$router.push({ name: 'NotCompatible' });
                }, this.countdownDuration * 1000);
            }
        },

        HandleMediaDeviceError(_error) {
            alert('Error while loading user media device: ' + _error);
        },

        classifyVideo(self) {
            console.log('predict');
            self.classifier.classify(this.gotResult);
        },

        gotResult(err, results) {
            console.log(results[0].label + ' ' + results[0].confidence);
            if (results[0].confidence > 0.5 && results[0].label != this.wrongLabel) {
                if (this.acceptedLabelsList.includes(results[0].label)) {
                    // Detect a usefull item
                    this.isObjectDetected = true;
                    this.isObjectUseless = false;
                    this.detectedObjectTag = results[0].label;
                    this.detectedObjectName = this.$t('items.' + results[0].label.trim());
                    if (this.detectedObjectName === 'Entrée') {
                        // Go to connection page
                        this.$router.push({ name: this.nextRoute });
                    }
                } else {
                    // Detect a useless item
                    this.isObjectDetected = true;
                    this.detectedObjectTag = results[0].label;
                    this.detectedObjectName = this.$t('items.' + results[0].label.trim());
                    this.isObjectUseless = true;
                }
            } else {
                this.isObjectDetected = false;
                this.isObjectUseless = false;
                this.detectedObjectTag = '';
                this.detectedObjectName = '';
            }
        },

        modelReady(classifier) {
            this.isLoading = false;
            console.log('Model is ready');

            this.classifier = classifier;
            this.intervalClassify = setInterval(() => {
                this.classifyVideo(this);
            }, 2000);
        },

        navigateAfterCollect() {
            if (this.isObjectDetected && !this.isObjectUseless) {
                this.$router.push({ name: this.nextRoute });
            }
        },

        processAction() {
            if (!this.isObjectUseless && this.detectedObjectName !== '') {
                if (this.useNavigation) {
                    this.navigateAfterCollect();
                } else {
                    this.$emit('collect', this.detectedObjectTag);
                }
            }
        },

        abandon() {
            this.$emit('collect', this.acceptedLabelsList[0]);
        },
    },

    beforeDestroy() {
        clearInterval(this.intervalClassify);

        //kill camera compatibility timeout
        if (this.countdownRef) {
            console.log('Kill timeout');
            clearTimeout(this.countdownRef);
        }
        console.log('Unmount camera');
    },
};
</script>

<style lang="scss">
.mlcamera {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2000;
    width: 100%;
    height: 100%;

    background-image: url('~@/assets/img/Texture_papier_dialogue_Tile.jpg');
    background-size: cover;

    .loading-screen {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;

        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        font-family: 'Londrina Solid';
        font-size: 2em;
        color: rgb(254, 254, 235);

        background-image: url('~@/assets/img/Texture_papier_bleu_fonce_Tile750x1334.jpg');
        background-size: cover;

        img {
            display: block;
            margin: 1em 0;

            animation: rotation 2s infinite;

            @keyframes rotation {
                0% {
                    transform: scale(1);
                }
                50% {
                    transform: scale(1.1);
                }
                100% {
                    transform: scale(1);
                }
            }
        }
    }

    .content {
        width: 100%;
        height: 100%;

        .target {
            position: fixed;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;

            display: flex;
            justify-content: center;
            align-items: center;

            img {
                width: 4em;
                height: auto;
            }
        }

        .frame {
            position: fixed;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;

            display: flex;
            justify-content: center;
            align-items: center;

            img {
                width: 160%;
                height: auto;
            }
        }

        .useless-indicator {
            position: fixed;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;

            display: flex;
            justify-content: center;
            align-items: center;

            span {
                display: block;
                margin: auto;
                padding: 20px 20px;
                width: 50%;
                height: auto;

                font-family: 'Londrina Solid', cursive;
                color: rgb(254, 254, 235);
                background-color: #d97042;
                font-size: 1.3em;
                text-align: center;
            }
        }

        .instructions {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;

            padding: 1em 0;

            font-family: 'Londrina Solid', cursive;
            font-size: 1.3em;
            color: rgb(254, 254, 235);
            text-align: center;

            span {
                line-height: 1.6em;
                padding: 5px 10px;
                background-color: rgb(0, 71, 68);
            }
        }

        .object-label {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;

            padding: 2.5em 0;

            font-family: 'Londrina Solid', cursive;
            font-size: 1.3em;
            color: #004744;
            text-align: center;

            span {
                line-height: 1.6em;
                padding: 10px 20px;
                background-color: #b8ddc5;
            }
        }

        video {
            width: 100%;
            height: 100%;
            z-index: -1;

            object-fit: fill;
        }

        .controls {
            position: fixed;
            bottom: 0;
            left: 0;
            right: 0;
            display: flex;
            flex-basis: 100%;
            justify-content: space-evenly;

            padding: 1em 0;

            font-family: 'Londrina Solid';
            font-size: 1.5em;
            text-align: center;

            a {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 40%;
                height: 3em;

                border-radius: 3px;

                &#help {
                    background-color: rgb(0, 71, 68);
                    color: rgb(126, 248, 132);
                }

                &#collect {
                    background-color: rgb(126, 248, 132);
                    color: rgb(0, 71, 68);

                    img {
                        width: auto;
                        height: 50%;
                    }

                    &.inactive {
                        img {
                            filter: grayscale(1) opacity(0.5);
                        }
                        background-color: #cccccc;
                        color: #677978;
                    }
                }
            }
        }
    }
}
</style>
