<template>
    <div class="settings">
        <div class="top-bar">
            <a class="back-btn" @click="onBackClicked" role="button" aria-label="Fermer">
                <img src="@/assets/img/back_arrow.png" role="presentation" />
            </a>

            <div class="title">
                <img src="@/assets/img/icone_reglages.svg" />
                <h1>{{ $t('settings.title') }}</h1>
            </div>
        </div>

        <div class="content">
            <div class="section" id="text-size">
                <h1 class="section-title">{{ $t('settings.sizelbl') }}</h1>
                <div class="section-content">
                    <span class="tag" id="little">Aa</span>

                    <Slider
                        :min="0.5"
                        :max="2"
                        :step="0.5"
                        :startVal="textSize"
                        list="tickmarks"
                        @change="setTextSize"
                        ariaLabel="Ajustement de la taille des textes"
                    >
                        <datalist id="tickmarks">
                            <option :value="textOpts.size.small" aria-label="taille du texte, petite" />
                            <option :value="textOpts.size.medium" aria-label="taille du texte, moyenne" />
                            <option :value="textOpts.size.normal" aria-label="taille du texte, normal" />
                            <option :value="textOpts.size.big" aria-label="taille du texte, grande" />
                        </datalist>
                    </Slider>

                    <span class="tag" id="big">Aa</span>
                </div>
            </div>

            <hr />

            <div class="section" id="text-speed">
                <h1 class="section-title">{{ $t('settings.speedlbl') }}</h1>
                <div class="section-content">
                    <span class="tag">{{ $t('settings.slowlbl') }}</span>

                    <Slider
                        :min="0"
                        :max="4"
                        :step="2"
                        :startVal="textSpeed"
                        list="tickmarks"
                        @change="setTextSpeed"
                        ariaLabel="Ajustement de la vitesse de défilement du texte"
                    >
                        <datalist id="tickmarks">
                            <option :value="textOpts.speed.slow" aria-label="vitesse lente" />
                            <option :value="textOpts.speed.normal" aria-label="vitesse normal" />
                            <option :value="textOpts.speed.speed" aria-label="vitesse rapide" />
                        </datalist>
                    </Slider>

                    <span class="tag">{{ $t('settings.fastlbl') }}</span>
                </div>
            </div>

            <hr />

            <div class="section" id="text-lang">
                <h1 class="section-title">{{ $t('settings.langlbl') }}</h1>
                <div class="section-content">
                    <a class="lang-btn" :class="textLang === languages.fr ? 'active' : ''" @click="setTextLang(languages.fr)" role="button"
                        >FRANÇAIS</a
                    >
                    <a class="lang-btn" :class="textLang === languages.en ? 'active' : ''" @click="setTextLang(languages.en)" role="button"
                        >ENGLISH</a
                    >
                    <a class="lang-btn" :class="textLang === languages.es ? 'active' : ''" @click="setTextLang(languages.es)" role="button"
                        >ESPAÑOL</a
                    >
                </div>
            </div>

            <hr />

            <Credits></Credits>
        </div>
    </div>
</template>

<script>
import Slider from '../components/Slider';
import Credits from '@/components/Credits';

export default {
    components: {
        Slider,
        Credits,
    },

    data() {
        return {
            languages: require('../utils/config').languages,
            textOpts: require('../utils/config').textOptions,
            formerSelectedLanguage: '',
        };
    },

    mounted() {
        this.formerSelectedLanguage = this.$store.state.textLang;
    },

    beforeDestroy() {
        // Check if user changed language
        if (this.formerSelectedLanguage !== this.$store.state.textLang) {
            this.$emit('language-changed');
        }
    },

    methods: {
        onBackClicked() {
            this.$emit('on-close');
        },

        setTextSize(value) {
            this.$store.commit({ type: 'setTextSize', value: value });
        },

        setTextSpeed(value) {
            this.$store.commit({ type: 'setTextSpeed', value: value });
        },

        setTextLang(value) {
            //TO DO : change story script
            this.$store.commit({ type: 'setTextLang', value: value, i18n: this.$i18n });
        },
    },

    computed: {
        textSize() {
            return Number.parseFloat(this.$store.state.textSize);
        },

        textSpeed() {
            return Number.parseFloat(this.$store.state.textSpeed);
        },

        textLang() {
            return this.$store.state.textLang;
        },
    },
};
</script>

<style lang="scss">
.settings {
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    background-image: url('~@/assets/img/Texture_papier_blanc_casse_Tile750x1334.jpg');
    background-size: cover;

    .top-bar {
        display: flex;
        width: 100%;
        height: 60px;

        background-color: #fefeeb;

        .back-btn {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 60px;
            height: 100%;

            background-color: #92ff97;

            img {
                width: 30%;
                height: auto;
            }
        }

        .title {
            display: flex;
            align-items: center;
            height: 100%;

            img {
                width: 20px;
                height: auto;

                margin: 0 20px;
            }

            h1 {
                margin: 0;
                line-height: 1em;

                font-size: 1.2em;
                font-family: 'Londrina Solid', serif;
                color: rgb(0, 71, 68);
            }
        }
    }

    .content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 10px 20px 20px 20px;
        height: calc(100% - 100px);

        hr {
            margin: 20px 0;
            border: none;
            border-top: 2px dotted rgba(0, 71, 68, 0.8);
        }

        .section {
            flex: 1;

            .section-title {
                margin: 10px 10px;
                font-family: 'Londrina Solid', serif;
                font-size: 1.8em;
                font-weight: 400;
                color: rgb(0, 71, 68);
            }

            .section-content {
                display: flex;
                justify-content: center;
                align-items: center;
            }

            &#text-size {
                .tag {
                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 60px;
                    height: 45px;
                    border-radius: 0 15px 15px 5px;
                    background-color: rgb(0, 71, 68);
                    color: #fefeeb;
                    font-weight: bold;

                    &::before {
                        content: '\A';
                        border-style: solid;
                        border-width: 10px 0px 10px 10px;
                        border-color: rgb(0, 71, 68) rgb(0, 71, 68) transparent transparent;
                        position: absolute;
                        top: 0;
                        left: -10px;
                    }

                    &#little {
                        font-size: 0.9em;
                    }

                    &#big {
                        font-size: 1.3em;
                    }
                }
            }

            &#text-speed {
                .tag {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 75px;
                    height: 30px;
                    border-radius: 10px;
                    color: rgb(0, 71, 68);
                    font-weight: 600;
                }
            }

            &#text-lang {
                .lang-btn {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    height: 50px;

                    font-weight: bold;
                    color: rgb(0, 71, 68);

                    &.active {
                        background-color: rgb(0, 71, 68);
                        color: #fefeeb;
                    }
                }
            }
        }

        .credits {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
        }
    }
}
</style>
