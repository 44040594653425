<template>
    <div class="language-selector">
        <a
            class="language-selector-btn"
            :style="'color:' + color"
            @click="OnLanguageSelectorBtnClicked"
            v-if="!isModalOpen"
            role="button"
            aria-label="Changer de langue"
        >
            {{ $t('home.langindicator') }}
        </a>

        <div
            class="language-selector-modal"
            v-if="isModalOpen"
        >
            <div class="modal-container">
                <div class="modal-header">
                    <a @click="OnModalCloseBtnClicked" role="button" aria-label="Fermer la sélection de langue"><img src="@/assets/img/croix.svg"></a>
                </div>
                <div class="modal-content">
                    <a :class="textLang === 'fr' ? 'active': ''" @click="changeLanguage('fr')" role="button">FRANÇAIS</a>
                    <a :class="textLang === 'en' ? 'active': ''" @click="changeLanguage('en')" role="button">ENGLISH</a>
                    <a :class="textLang === 'es' ? 'active': ''" @click="changeLanguage('es')" role="button">ESPAÑOL</a>
                </div>
                <div class="modal-expander"></div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                isModalOpen: false
            }
        },

        props: {
            color: {
                type: String,
                default: 'rgb(254, 254, 235)'
            }
        },

        methods: {
            OnLanguageSelectorBtnClicked()
            {
                // Open the modal
                this.isModalOpen = true;
            },

            OnModalCloseBtnClicked()
            {
                // Close the modal
                this.isModalOpen = false;
            },

            changeLanguage(lang)
            {
                this.$store.commit('setTextLang', {value: lang, i18n: this.$i18n});
                // Close the modal
                this.isModalOpen = false;
            }
        },

        computed: {
            textLang()
            {
                return this.$store.state.textLang;
            }
        }
    }
</script>

<style lang="scss">
    .language-selector
    {
        .language-selector-btn
        {
            position: fixed;
            top: 20px;
            right: 20px;
            z-index: 1;

            font-weight: 600;
        }

        .language-selector-modal
        {
            position: fixed;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;

            padding: 25px;

            background-image: url("~@/assets/img/Texture_papier_bleu_fonce_Tile750x1334.jpg");
            background-size: cover;

            .modal-container
            {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                width: 100%;
                height: 100%;

                border-radius: 5px;
                background-color: #7ef884;
                box-shadow: 3px 3px 4px 0 rgba(56, 104, 93, 0.3);

                .modal-header
                {
                    justify-content: flex-start;
                    padding: 10px;
                    text-align: right;

                    a
                    {
                        img
                        {
                            justify-self: right;
                            width: auto;
                            height: 25px;
                        }
                    }
                }

                .modal-content
                {
                    justify-self: center;
                    display: flex;
                    flex-direction: column;
                    height: 1fr;
                    justify-content: center;

                    padding: 30px;

                    a
                    {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 100%;
                        height: 50px;
                        margin: 10px 0;

                        font-weight: bold;
                        color: rgb(0, 71, 68);
                        font-family: "Londrina Solid", cursive;
                        font-size: 2em;
                        letter-spacing: 3px;

                        &.active
                        {
                            background-color: rgb(0, 71, 68);
                            color: #fefeeb;
                        }
                    }
                }
            }
        }
    }
</style>