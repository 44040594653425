<template>
    <div class="connection">
        <span class="lang">{{ $t('home.langindicator') }}</span>

        <img class="logo" src="@/assets/img/connection_logo.svg" alt="Logo Allo Survie" />

        <hr />

        <span class="instructions">
            <span v-html="$t('connection.text01')"></span>
            <br /><br />
            <span v-html="$t('connection.text02')"></span>
        </span>

        <a class="connection-btn" @click="onConnectionBtnClicked" role="button">{{ $t('connection.connectionbtn') }}</a>

        <LanguageSelector color="rgb(0, 71, 68)"></LanguageSelector>
    </div>
</template>

<script>
import LanguageSelector from '@/components/LanguageSelector';

export default {
    components: {
        LanguageSelector,
    },

    methods: {
        onConnectionBtnClicked() {
            this.$router.push({ name: 'DialogHome' });
        },
    },
};
</script>

<style lang="scss">
.connection {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    padding: 0px 25px 25px 25px;

    text-align: center;

    background-image: url('~@/assets/img/Texture_papier_blanc_casse_Tile750x1334.jpg');
    background-size: cover;

    .lang {
        position: fixed;
        top: 20px;
        right: 20px;

        font-size: 1em;
        font-weight: 600;
        color: rgb(0, 71, 68);
    }

    .logo {
        width: 75%;
        height: auto;
    }

    hr {
        display: block;
        width: 100%;
        margin: 10px;
        border: none;
        border-top: 2px dotted rgba(0, 71, 68, 0.8);
    }

    .instructions {
        font-size: 1em;
        font-weight: 600;
        color: rgb(0, 71, 68);
    }

    .connection-btn {
        display: flex;
        justify-content: center;
        align-items: center;

        width: 250px;
        height: 75px;
        background-color: rgb(0, 71, 68);
        color: rgb(126, 248, 132);
        border: none;
        border-radius: 3px;

        font-family: 'Londrina Solid', cursive;
        text-transform: uppercase;

        font-size: 2em;
    }
}
</style>
