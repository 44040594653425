<template>
    <div class="slider">
        <input type="range" :step="step" :min="min" :max="max" :value="value" :list="list" @change="onChange" :aria-label="ariaLabel">

        <slot></slot>
    </div>
</template>

<script>
    export default {
        props: {
            step: Number,
            min: Number,
            max: Number,
            list: String,
            startVal: Number,
            ariaLabel: String
        },

        data()
        {
            return {
                value: 0
            }
        },

        mounted()
        {
            this.value = this.startVal;
        },

        methods: {
            onChange(e)
            {
                this.value = e.target.value;
                this.$emit('change', e.target.value);
            }
        }
    }
</script>

<style lang="scss">
    .slider
    {
        display: grid;
        width: 50%;
        margin: 20px 20px;

        input[type="range"]
        {
            appearance: none;
            background: transparent;
            
            margin: 0px;
            padding: 0px;

            width: 100%;
            height: 3px;

            &::-webkit-slider-runnable-track
            {
                appearance: none;
                width: 95%;
                height: 100%;
                background-color: rgb(0, 71, 68);
            }

            &::-webkit-slider-thumb
            {
                appearance: none;
                margin-top: -8px;
                border: 2px solid  rgb(0, 71, 68);
                background-color: #92ff97;
                width: 20px;
                height: 20px;
                border-radius: 50%;
            }
        }

        datalist 
        {
            display: flex;
            justify-content: space-between;
            width: 100%;
            height: 7px;
            align-items: center;
            padding: -9px 5px 0px 5px;
            margin-top: -5px;

            option 
            {
                display: block;
                width: 3px;
                min-height: 0px;
                height: 6px;
                border-radius: 50%;
                background-color: rgb(0, 71, 68);
                z-index: -1;
            }
        }
    }
</style>