<template>
    <div class="home">
        <span class="app-version">v {{ this.appVersion }}</span>
        <img class="logo" src="@/assets/img/ALLOSURVIE_Logo_FR.svg" alt="Logo Allo Survie" />

        <div class="content">
            <span class="instructions">
                {{ $t('home.message') }}
            </span>

            <a class="scan" @click="goToScan()" role="button">
                <img src="@/assets/img/icone_camera.svg" role="presentation" />
                <!-- {{ $t('home.scanbtn') }} -->
            </a>
        </div>

        <!-- Language Selector -->
        <LanguageSelector></LanguageSelector>
    </div>
</template>

<script>
import LanguageSelector from '@/components/LanguageSelector';
import Config from '@/utils/config';

export default {
    name: 'Home',
    components: {
        LanguageSelector,
    },

    data() {
        return {
            appVersion: '',
        };
    },

    mounted() {
        this.appVersion = Config.appVersionNumber;
    },
    methods: {
        goToScan() {
            this.$router.push({ name: 'EnterAppScan' });
        },
    },
};
</script>

<style lang="scss">
.home {
    display: flex;
    flex-direction: column;
    width: 100%;
    // height: calc(100% - 50px);
    height: 100%;

    padding: 50px 0 0 0;

    background-image: url('~@/assets/img/Texture_papier_bleu_fonce_Tile750x1334.jpg');
    background-size: cover;

    justify-content: space-evenly;
    align-items: center;
    text-align: center;

    .app-version {
        position: fixed;
        top: 20px;
        left: 20px;

        font-size: 0.7em;
        // font-weight: 600;
        color: rgb(254, 254, 235);
    }

    .lang {
        display: inline-block;

        padding: 15px;
        text-align: right;

        font-size: 1em;
        color: rgb(254, 254, 235);
    }

    .logo {
        display: inline-block;
        width: auto;
        height: 50vh;

        margin: 0 auto;
    }

    .content {
        flex: 1;
        // height: 100%;

        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;

        .instructions {
            display: inline-block;

            font-size: 1.2em;
            font-weight: 600;
            margin: 0 30px 0 30px;
            color: rgb(254, 254, 235);
        }

        .scan {
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            margin: 0 auto 25px auto;

            width: 250px;
            height: 75px;
            background-color: rgb(126, 248, 132);
            color: rgb(0, 71, 68);
            border: none;
            border-radius: 3px;

            font-family: 'Londrina Solid', cursive;

            font-size: 2em;

            img {
                width: 1.3em;
                height: auto;
            }
        }
    }
}
</style>
