<template>
    <div class="blueprint">
        <div class="help-btn" @click="$emit('open-manual')"><span>?</span></div>
        <img v-for="(item, i) in itemsToDisplay" :key="i" class="schema" :src="item" />
    </div>
</template>

<script>
import Config from '@/utils/config';

export default {
    props: {
        currentRecipe: Object,
    },

    data() {
        return {
            itemsToDisplay: [],
        };
    },

    mounted() {
        for (var i = 0; i < this.currentRecipe.craft.length; i++) {
            var item = this.currentRecipe.craft[i];
            // Check condition is composed of:
            // - toDisplay (bool)
            // - count (Number)
            // If count is 0 so we don't take it in consideration
            let condition = item.checkCondition(this.$store);
            // console.log(condition);

            if (condition.toDisplay) {
                if (condition.count <= 0) {
                    this.itemsToDisplay.push(this.getImageUrl(item.name));
                } else {
                    this.itemsToDisplay.push(this.getImageURLWidthIndex(item.name, condition.count, condition.index));
                }
            }
        }
    },

    methods: {
        getImageUrl(itemName) {
            if (this.$store.state.inventoryContent.includes(itemName)) {
                return Config.publicAssetsBaseUrl + this.currentRecipe.items[itemName];
            }

            return Config.publicAssetsBaseUrl + this.currentRecipe.blueprints[itemName];
        },

        getImageURLWidthIndex(itemName, count, index) {
            if (index <= count) {
                return Config.publicAssetsBaseUrl + this.currentRecipe.items[itemName];
            }

            return Config.publicAssetsBaseUrl + this.currentRecipe.blueprints[itemName];
        },
    },
};
</script>

<style lang="scss" scoped>
.blueprint {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0 0 0;
    width: 100%;
    min-height: 80vw;

    overflow: hidden;

    background-image: url('~@/assets/img/blueprint_background.png');
    background-size: cover;

    img.schema {
        position: absolute;
        top: 25%;
        bottom: 25px;
        left: 25px;
        right: 25px;
    }
    .help-btn {
        position: absolute;
        top: 5px;
        right: 5px;
        width: 25px;
        height: 25px;
        border-radius: 50% 50%;
        text-align: center;
        background-color: #7ef884;
        display: flex;
        align-items: center;
        justify-content: center;
        span {
            font-family: 'Londrina Solid', serif;
            color: #004744;
        }
    }
}
</style>
