<template>
    <div class="inventory">
        <div
            class="inventory-btn"
            :class="isInventoryNotified ? 'active-notification' : ''"
            @click="openInventoryPanel"
            role="button"
            aria-label="Ouvrir l'inventaire"
        >
            <img id="background" src="@/assets/img/hexagone.svg" role="presentation" />
            <img id="icon" src="@/assets/img/icone_sac_inventaire.svg" role="presentation" />
        </div>

        <div v-if="isPanelOpened" class="inventory-panel">
            <div class="top-bar">
                <a class="back-btn" @click="closeInventoryPanel" role="button" aria-label="Fermer">
                    <img src="@/assets/img/back_arrow.png" />
                </a>

                <div class="titles">
                    <div class="title" id="bag">
                        <img src="@/assets/img/icone_sac_inventaire.svg" />
                        <h1>{{ $t('inventory.sac_a_dos') }}</h1>
                    </div>

                    <div class="title" id="crafting" v-if="isCraftingBookAccessible()" @click="switchToCraftingBook" role="button">
                        <img src="@/assets/img/useful_icon.png" role="presentation" />
                        <h1>{{ $t('inventory.carnet_de_fabrication') }}</h1>
                    </div>
                </div>
            </div>

            <div class="content">
                <ItemSlot v-for="(item, i) in inventoryContent" :key="i" :item-name="item.trim()" />

                <!-- EXCEPTIONS CASES -->
                <!-- Branchages -->
                <ItemSlot v-for="i in storyManager.getVariable('BRANCHAGES')" :key="i" item-name="BRANCHAGES" />
                <!-- Fourgeres -->
                <ItemSlot v-for="i in storyManager.getVariable('FOUGERES')" :key="i" item-name="FOUGERES" />
            </div>
        </div>
    </div>
</template>

<script>
import ItemSlot from '@/components/Inventory/ItemSlot';

export default {
    components: {
        ItemSlot,
    },

    data() {
        return {
            isPanelOpened: false,
            storyManager: require('../story/StoryManager').default,
        };
    },

    beforeUpdate() {
        // Set craft screen as viewed if craft screen should be consulted
        if (this.$store.state.inventoryScreenShouldBeConsulted) {
            this.$store.commit('setInventoryScreenConsulted', { value: true });
        }
    },

    methods: {
        openInventoryPanel() {
            // Open the panel
            this.isPanelOpened = true;
            this.$emit('on-open');

            // Set inventory notified
            this.$store.commit('setIsInventoryNotified', { value: false });
        },

        closeInventoryPanel() {
            this.$emit('on-close');
            this.isPanelOpened = false;
        },

        switchToCraftingBook() {
            this.$emit('open-crafting');
            this.isPanelOpened = false;
        },

        isCraftingBookAccessible() {
            return this.currentRecipeIndex >= 0;
        },
    },

    computed: {
        inventoryContent() {
            return this.$store.state.inventoryContent;
        },

        currentRecipeIndex() {
            return this.$store.state.currentRecipeIndex;
        },

        isInventoryNotified() {
            return this.$store.state.isInventoryNotified;
        },
    },
};
</script>

<style lang="scss">
.inventory {
    width: 50%;
    height: 100%;

    .inventory-btn {
        position: relative;
        width: 100%;
        height: 100%;

        #background {
            width: auto;
            height: 100%;
        }

        #icon {
            position: absolute;
            width: auto;
            height: 65%;
            top: 15%;
            left: 15%;
        }

        &.active-notification {
            #icon {
                animation-name: notification;
                animation-iteration-count: infinite;
                animation-duration: 1s;
            }
        }

        @keyframes notification {
            0% {
                transform: scale(1);
                filter: brightness(100%) sepia(0%);
            }

            50% {
                transform: scale(1.3);
                filter: brightness(150%) sepia(50%);
            }

            100% {
                transform: scale(1);
                filter: brightness(100%) sepia(0%);
            }
        }
    }

    .inventory-panel {
        position: absolute;
        z-index: 1;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;

        background-image: url('~@/assets/img/Texture_papier_bleu_fonce_Tile750x1334.jpg');
        background-size: cover;

        .top-bar {
            display: flex;
            width: 100%;
            height: 60px;

            background-color: #004744;

            .back-btn {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 60px;
                min-width: 60px;
                height: 100%;

                background-color: #92ff97;

                img {
                    width: 30%;
                    height: auto;
                }
            }

            .titles {
                display: flex;
                justify-content: space-around;

                width: 100%;

                .title {
                    flex: 1;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    height: 100%;

                    padding: 0 10px;

                    img {
                        width: 20px;
                        height: auto;

                        margin: 0 10px 0 0;
                    }

                    h1 {
                        margin: 0;
                        line-height: 1em;

                        font-size: 1.2em;
                        font-family: 'Londrina Solid', serif;
                        font-weight: 400;
                        color: #fefeeb;
                    }

                    &#bag {
                        padding: 0 10px 0 15px;
                    }

                    &#crafting {
                        box-sizing: border-box;
                        background-color: #fefeeb;
                        border-width: 0 0 5px 5px;
                        border-color: transparent transparent #d1e5ce #d1e5ce;
                        border-style: solid;

                        h1 {
                            color: #004744;
                        }
                    }
                }
            }
        }

        .content {
            display: grid;
            justify-items: center;
            grid-template-columns: 1fr 1fr;
            grid-auto-rows: auto;
            padding: 20px 0 0 0;

            max-height: calc(100% - 80px);

            overflow-x: hidden;
            overflow-y: auto;
        }
    }
}
</style>
