import StoryManager from "@/story/StoryManager";

/**
 * Inventory Manager
 *
 * Manage inventory data between
 */
class InventoryManager
{
    itemsNotInInventoryList = null;
    itemsNotInInventoryScanned = null;
    itemsInInventoryList = null;
    inventoryContent = [];

    craftRecipes = [
        {
            name: "COLLET",
            required_items: ['CORDELETTE', 'FIL_DE_FER', 'BRANCHE_COURBE'],
            require_one_of: [],
            craft: [
                {
                    name: 'BRANCHE_COURBE',
                    // eslint-disable-next-line no-unused-vars
                    checkCondition: function(store) {
                        return {
                            toDisplay: true,
                            count: 0
                        };
                    }
                },
                {
                    name: 'CORDELETTE',
                    // eslint-disable-next-line no-unused-vars
                    checkCondition: function(store) {
                        if(store.state.inventoryContent.includes('FIL_DE_FER') &&
                            !store.state.inventoryContent.includes('CORDELETTE')
                        )
                            return {
                                toDisplay: false,
                                count: 0
                            };

                        return {
                            toDisplay: true,
                            count: 0
                        };
                    }
                },
                {
                    name: 'FIL_DE_FER',
                    // eslint-disable-next-line no-unused-vars
                    checkCondition: function(store) {
                        if(store.state.inventoryContent.includes('CORDELETTE'))
                            return {
                                toDisplay: false,
                                count: 0
                            };
                        else if(!store.state.inventoryContent.includes('FIL_DE_FER'))
                            return {
                                toDisplay: false,
                                count: 0
                            };

                        return {
                            toDisplay: true,
                            count: 0
                        };
                    }
                }
            ],
            enabled: false,
            recipe_available: false,
            blueprints: {
                'CORDELETTE': '/img/illustrations/ObjetsCrafts/Collet/CSI_Survie_Illus_Objet_Collet_66_blanc.png',
                'FIL_DE_FER': '/img/illustrations/ObjetsCrafts/Collet/CSI_Survie_Illus_Objet_Collet_65_blanc.png',
                'BRANCHE_COURBE': '/img/illustrations/ObjetsCrafts/Collet/CSI_Survie_Illus_Objet_Collet_68_blanc.png'
            },
            items: {
                'CORDELETTE': '/img/illustrations/ObjetsCrafts/Collet/CSI_Survie_Illus_Objet_Collet_66.png',
                'FIL_DE_FER': '/img/illustrations/ObjetsCrafts/Collet/CSI_Survie_Illus_Objet_Collet_65.png',
                'BRANCHE_COURBE': '/img/illustrations/ObjetsCrafts/Collet/CSI_Survie_Illus_Objet_Collet_68.png'
            }
        },
        {
            name: "FILTRE_A_EAU",
            required_items: ['BOUTEILLE'],
            require_one_of: ['SABLE', 'GRAVIER', 'CHARBON'],
            craft: [
                {
                    name: 'BOUTEILLE',
                    // eslint-disable-next-line no-unused-vars
                    checkCondition: function(store) {
                        return {
                            toDisplay: true,
                            count: 0
                        };
                    }
                },
                {
                    name: 'SABLE',
                    // eslint-disable-next-line no-unused-vars
                    checkCondition: function(store) {
                        return {
                            toDisplay: true,
                            count: 0
                        };
                    }
                },
                {
                    name: 'GRAVIER',
                    // eslint-disable-next-line no-unused-vars
                    checkCondition: function(store) {
                        return {
                            toDisplay: true,
                            count: 0
                        };
                    }
                },
                {
                    name: 'CHARBON',
                    // eslint-disable-next-line no-unused-vars
                    checkCondition: function(store) {
                        return {
                            toDisplay: true,
                            count: 0
                        };
                    }
                }
            ],
            enabled: false,
            recipe_available: false,
            blueprints: {
                'BOUTEILLE': '/img/illustrations/ObjetsCrafts/FiltreAEau/Blueprint/CSI_Survie_Illus_Objet_FiltreAEau_Bouteille.png',
                'SABLE': '/img/illustrations/ObjetsCrafts/FiltreAEau/Blueprint/CSI_Survie_Illus_Objet_FiltreAEau_Filtrant_2_blueprint.png',
                'GRAVIER': '/img/illustrations/ObjetsCrafts/FiltreAEau/Blueprint/CSI_Survie_Illus_Objet_FiltreAEau_Filtrant_3_blueprint.png',
                'CHARBON': '/img/illustrations/ObjetsCrafts/FiltreAEau/Blueprint/CSI_Survie_Illus_Objet_FiltreAEau_Filtrant_1_blueprint.png'
            },
            items: {
                'BOUTEILLE': '/img/illustrations/ObjetsCrafts/FiltreAEau/Blueprint/CSI_Survie_Illus_Objet_FiltreAEau_Bouteille.png',
                'SABLE': '/img/illustrations/ObjetsCrafts/FiltreAEau/Blueprint/CSI_Survie_Illus_Objet_FiltreAEau_Filtrant_2.png',
                'GRAVIER': '/img/illustrations/ObjetsCrafts/FiltreAEau/Blueprint/CSI_Survie_Illus_Objet_FiltreAEau_Filtrant_3.png',
                'CHARBON': '/img/illustrations/ObjetsCrafts/FiltreAEau/Blueprint/CSI_Survie_Illus_Objet_FiltreAEau_Filtrant_1.png'
            }
        },
        {
            name:"CANNE",
            required_items: ['BATON', 'VERS_DE_TERRE'],
            require_one_of: [],
            craft: [
                {
                    name: 'BATON',
                    // eslint-disable-next-line no-unused-vars
                    checkCondition: function (store) {
                        return {
                            toDisplay: true,
                            count: 0
                        };
                    }
                },
                {
                    name: 'VERS_DE_TERRE',
                    // eslint-disable-next-line no-unused-vars
                    checkCondition: function (store) {
                        if(store.state.inventoryContent.includes('BATON'))
                            return {
                                toDisplay: false,
                                count: 0
                            };

                        return {
                            toDisplay: true,
                            count: 0
                        };
                    }
                }
            ],
            enabled: false,
            recipe_available: false,
            blueprints: {
                'BATON': '/img/illustrations/ObjetsCrafts/CanneAPeche/CSI_Survie_Illus_Objet_CanneAPeche_03_blanc.png',
                'VERS_DE_TERRE': '/img/illustrations/ObjetsCrafts/CanneAPeche/CSI_Survie_Illus_Objet_CanneAPeche_KitDePeche.png'
            },
            items: {
                'BATON': '/img/illustrations/ObjetsCrafts/CanneAPeche/CSI_Survie_Illus_Objet_CanneAPeche.png',
                'VERS_DE_TERRE': '/img/illustrations/ObjetsCrafts/CanneAPeche/CSI_Survie_Illus_Objet_CanneAPeche_KitDePeche.png'
            }
        },
        {
            name: "ARCHET",
            required_items: ['CORDELETTE', 'BRANCHE_COURBE', 'PLANCHETTE'],
            require_one_of: [],
            craft: [
                {
                    name: 'BRANCHE_COURBE',
                    // eslint-disable-next-line no-unused-vars
                    checkCondition: function (store) {
                        return {
                            toDisplay: true,
                            count: 0
                        };
                    }
                },
                {
                    name: 'PLANCHETTE',
                    // eslint-disable-next-line no-unused-vars
                    checkCondition: function (store) {
                        return {
                            toDisplay: true,
                            count: 0
                        };
                    }
                },
                {
                    name: 'CORDELETTE',
                    // eslint-disable-next-line no-unused-vars
                    checkCondition: function (store) {
                        return {
                            toDisplay: true,
                            count: 0
                        };
                    }
                },
            ],
            enabled: false,
            recipe_available: false,
            blueprints: {
                'CORDELETTE': '/img/illustrations/ObjetsCrafts/Archet/CSI_Survie_Illus_Objet_Archet_67_blanc.png',
                'PLANCHETTE': '/img/illustrations/ObjetsCrafts/Archet/CSI_Survie_Illus_Objet_Archet_34_blanc.png',
                'BRANCHE_COURBE': '/img/illustrations/ObjetsCrafts/Archet/CSI_Survie_Illus_Objet_Archet_68_blanc.png'
            },
            items: {
                'CORDELETTE': '/img/illustrations/ObjetsCrafts/Archet/CSI_Survie_Illus_Objet_Archet_67.png',
                'PLANCHETTE': '/img/illustrations/ObjetsCrafts/Archet/CSI_Survie_Illus_Objet_Archet_34.png',
                'BRANCHE_COURBE': '/img/illustrations/ObjetsCrafts/Archet/CSI_Survie_Illus_Objet_Archet_68.png'
            }
        },
        {
            name: "ABRI",
            required_items: ['TRONC', 'BRANCHAGES-1', 'BRANCHAGES-2', 'BRANCHAGES-3', 'FOUGERES-1', 'FOUGERES-2', 'FOUGERES-3'],
            require_one_of: [],
            craft: [
                {
                    name: 'TRONC',
                    // eslint-disable-next-line no-unused-vars
                    checkCondition: function (store) {
                        return {
                            toDisplay: true,
                            count: 0
                        };
                    }
                },
                {
                    name: 'BRANCHAGES-1',
                    // eslint-disable-next-line no-unused-vars
                    checkCondition: function (store) {
                        return {
                            toDisplay: true,
                            index: 1,
                            count: StoryManager.getVariable('BRANCHAGES')
                        };
                    }
                },
                {
                    name: 'BRANCHAGES-2',
                    // eslint-disable-next-line no-unused-vars
                    checkCondition: function (store) {
                        return {
                            toDisplay: true,
                            index: 2,
                            count: StoryManager.getVariable('BRANCHAGES')
                        };
                    }
                },
                {
                    name: 'FOUGERES-1',
                    // eslint-disable-next-line no-unused-vars
                    checkCondition: function (store) {
                        return {
                            toDisplay: true,
                            index: 1,
                            count: StoryManager.getVariable('FOUGERES')
                        };
                    }
                },
                {
                    name: 'FOUGERES-2',
                    // eslint-disable-next-line no-unused-vars
                    checkCondition: function (store) {
                        return {
                            toDisplay: true,
                            index: 2,
                            count: StoryManager.getVariable('FOUGERES')
                        };
                    }
                }
            ],
            enabled: false,
            recipe_available: false,
            blueprints: {
                'TRONC': '/img/illustrations/ObjetsCrafts/Abri/Blueprint/CSI_Survie_Illus_Objet_Abri_25_blueprint.png',
                'BRANCHAGES-1': '/img/illustrations/ObjetsCrafts/Abri/Blueprint/CSI_Survie_Illus_Objet_Abri_23_b_blanc_blueprint.png',
                'BRANCHAGES-2': '/img/illustrations/ObjetsCrafts/Abri/Blueprint/CSI_Survie_Illus_Objet_Abri_23_a_blanc_blueprint.png',
                'FOUGERES-1': '/img/illustrations/ObjetsCrafts/Abri/Blueprint/CSI_Survie_Illus_Objet_Abri_24_b_blanc_blueprint.png',
                'FOUGERES-2': '/img/illustrations/ObjetsCrafts/Abri/Blueprint/CSI_Survie_Illus_Objet_Abri_24_a_blanc_blueprint.png'
            },
            items: {
                'TRONC': '/img/illustrations/ObjetsCrafts/Abri/CSI_Survie_Illus_Objet_Abri_25.png',
                'BRANCHAGES-1': '/img/illustrations/ObjetsCrafts/Abri/CSI_Survie_Illus_Objet_Abri_23_b.png',
                'BRANCHAGES-2': '/img/illustrations/ObjetsCrafts/Abri/CSI_Survie_Illus_Objet_Abri_23_a.png',
                'FOUGERES-1': '/img/illustrations/ObjetsCrafts/Abri/CSI_Survie_Illus_Objet_Abri_24_b.png',
                'FOUGERES-2': '/img/illustrations/ObjetsCrafts/Abri/CSI_Survie_Illus_Objet_Abri_24_a.png'
            }
        },
        {
            name: "HARPON",
            required_items: ['BATON'],
            require_one_of: [],
            craft: [
                {
                    name: 'BATON',
                    // eslint-disable-next-line no-unused-vars
                    checkCondition: function (store) {
                        return {
                            toDisplay: true,
                            count: 0
                        };
                    }
                }
            ],
            enabled: false,
            recipe_available: false,
            blueprints: {
                'BATON': '/img/illustrations/ObjetsCrafts/Harpon/CSI_Survie_Illus_Objet_Harpon_51_blanc.png'
            },
            items: {
                'BATON': '/img/illustrations/ObjetsCrafts/Harpon/CSI_Survie_Illus_Objet_Harpon_51.png'
            }
        }
    ];

    constructor()
    {
    }

    setup()
    {
        this.itemsNotInInventoryList = StoryManager.getVariable('Items_Not_In_Inventory');
        this.itemsNotInInventoryScanned = [];
        this.itemsInInventoryList = StoryManager.getVariable('Items_In_Inventory');
        this.inventoryContent = [];
    }
}

let inventoryManager = new InventoryManager();
export default inventoryManager;